import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Divider,
  ThemeProvider
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import './styles.css';
import { COLORS } from '../../styles/settings/colors.config';

import FilterAttendants from './components/FilterAttendants';
import FilterStatus from './components/FilterStatus';
import { inputStyles, theme } from '../../components/inputStyle';

const FilterWrapper = styled('div')`
  overflow-y: auto;
  max-height: 650px;
  overflow-x: hidden;
  height: 100%;
  margin-bottom: 20px;
  padding-right: 15px;

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: ${COLORS.btnSecondaryColor};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.btnSecondaryColor};
    border: 2px solid ${COLORS.btnSecondaryColor};
  }

  @media (max-width: 1366px) {
    max-height: 390px;
  }
`;

const FilterContainer = ({
  filterData,
  setFilterData,
  onFilterChange,
  setOpen
}) => {
  const { t } = useTranslation();
  const classes = inputStyles();

  const [, setIsLoading] = useState(false);
  const [inputValueCreationDate, setInputValueCreationDate] = useState({
    from: '',
    to: ''
  });
  const [dateFrom, setDateFrom] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const [defaultState, setDefaultState] = useState({});

  const [
    inputValueCreationDateUseEffectParams,
    setInputValueCreationDateUseEffectParams
  ] = useState();

  const [filterForm, setFilterForm] = useState({});

  const orderByEnum = [
    { value: 'ASC', label: 'Mais antigos no topo' },
    { value: 'DESC', label: 'Mais recentes no topo' }
  ];

  const submitFilter = () => {
    const mergedFilterData = {
      ...filterData,
      ...filterForm
    };

    Object.entries(mergedFilterData).forEach(value => {
      const index = value[0];
      if (!mergedFilterData[index]) {
        delete mergedFilterData[index];
      }
    });
    if (inputValueCreationDate.from && inputValueCreationDate.to) {
      mergedFilterData.creationDate = `${moment
        .utc(inputValueCreationDate.from)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:00')}-${moment
        .utc(inputValueCreationDate.to)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:59')}`;
    }

    setFilterData(mergedFilterData);
    onFilterChange(mergedFilterData);

    sessionStorage.setItem(
      'preSalefilterUseQueryParams',
      JSON.stringify(false)
    );
  };

  const clearFilterFormCreationDateInputs = () => {
    const newState = { ...inputValueCreationDate };
    newState.from = '';
    newState.to = '';

    setInputValueCreationDateUseEffectParams('remove');
    setInputValueCreationDate(newState);
  };

  const clearFilterForm = () => {
    setIsLoading(true);

    clearFilterFormCreationDateInputs();

    const defaultStateData = {};

    defaultStateData.marketPlace = filterData.marketPlace;
    defaultStateData.ticketType = 'PRE_SALE';
    defaultStateData.creationDate = '';
    defaultStateData.ticketStatus = 'NEW';
    defaultStateData.serviceAttendantLogin = '';
    defaultStateData.orderBy = 'ASC';
    defaultStateData.keyword = '';
    defaultStateData.adsStatus = '';
    defaultStateData.questionId = '';
    defaultStateData.customerName = '';
    defaultStateData.customerEmail = '';
    defaultStateData.productName = '';

    sessionStorage.setItem(
      'preSalefilterData',
      JSON.stringify(defaultStateData)
    );

    setDefaultState({ ...defaultStateData });
    setDateFrom('');
    setTimeFrom('');
    setDateTo('');
    setTimeTo('');
  };

  const validadeInputValueCreation = () => {
    if (inputValueCreationDateUseEffectParams === 'remove') {
      setInputValueCreationDateUseEffectParams('');
    }
  };

  const changeDateTime = (toOrFrom, date, time) => {
    if (!time && date) {
      time = 'T00:00';
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setTimeFrom('00:00') : setTimeTo('23:59');
    }
    if (time) {
      time = `T${time}`;
    }
    if (!date && time) {
      date = moment(Date.now()).format('YYYY-MM-DD');
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setDateFrom(date) : setDateTo(date);
    }
    const newState = { ...inputValueCreationDate };
    // eslint-disable-next-line no-unused-expressions
    toOrFrom === 'from'
      ? (newState.from = `${date}${time}`)
      : (newState.to = `${date}${time}`);
    setInputValueCreationDate(newState);
  };

  useEffect(() => {
    setIsLoading(false);
    setFilterForm(defaultState);
  }, [defaultState]);

  useEffect(validadeInputValueCreation, [inputValueCreationDate]);

  useEffect(() => {
    changeDateTime('from', dateFrom, timeFrom);
  }, [dateFrom, timeFrom]);

  useEffect(() => {
    changeDateTime('to', dateTo, timeTo);
  }, [dateTo, timeTo]);

  useEffect(() => {
    clearFilterForm();
  }, [filterData.marketPlace]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container>
          <Grid item style={{ backgroundColor: '#f4f6f8' }}>
            <Box
              style={{
                width: '530px',
                padding: '12px 24px 0px',
                border: '0',
                display: 'Flex',
                flexDirection: 'column',
                borderLeft: '1px solid #ccc'
              }}
            >
              <FilterWrapper>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography variant="body1">
                    {t('i18n.ticketslistview.MORE_FILTER_OPTIONS')}
                  </Typography>
                </div>
                <br />

                <FilterAttendants
                  {...{ classes }}
                  filterForm={filterForm}
                  setFilterForm={setFilterForm}
                />
                <FilterStatus
                  {...{ classes }}
                  filterForm={filterForm}
                  setFilterForm={setFilterForm}
                  marketplace={filterData.marketPlace[0]}
                />
                {filterData.marketPlace.includes('YOURVIEWS') && (
                  <>
                    <Box mb={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <TextField
                            size="small"
                            value={filterForm.questionId || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                questionId: htmlElemtEvent.target.value
                              });
                            }}
                            fullWidth
                            className={classes.inputSmall}
                            label={t('i18n.dashboard.filterHeader.questionId')}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <TextField
                            size="small"
                            value={filterForm.customerName || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                customerName: htmlElemtEvent.target.value
                              });
                            }}
                            fullWidth
                            className={classes.inputSmall}
                            label={t(
                              'i18n.dashboard.filterHeader.customerName'
                            )}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={1}>
                      <Grid container spacing={2}>
                        <Grid item xs={7}>
                          <TextField
                            size="small"
                            value={filterForm.customerEmail || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                customerEmail: htmlElemtEvent.target.value
                              });
                            }}
                            fullWidth
                            className={classes.inputSmall}
                            label={t(
                              'i18n.dashboard.filterHeader.customerEmail'
                            )}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            size="small"
                            value={filterForm.productName || ''}
                            onChange={htmlElemtEvent => {
                              setFilterForm({
                                ...filterForm,
                                productName: htmlElemtEvent.target.value
                              });
                            }}
                            fullWidth
                            className={classes.inputSmall}
                            label={t('i18n.dashboard.filterHeader.productName')}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ marginTop: '15px' }}
                >
                  {t('i18n.ticketslistview.OPENING_DATE')}:
                </Typography>
                <br />
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        type="date"
                        label={t('i18n.dashboard.START_DATE')}
                        variant="outlined"
                        className={classes.inputSmall}
                        value={dateFrom}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        onChange={htmlElemtEvent => {
                          setDateFrom(htmlElemtEvent.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        type="date"
                        label={t('i18n.dashboard.END_DATE')}
                        variant="outlined"
                        value={dateTo}
                        className={classes.inputSmall}
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        onChange={htmlElemtEvent => {
                          setDateTo(htmlElemtEvent.target.value);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mb={2}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    style={{ marginTop: '15px' }}
                  >
                    {t('i18n.dashboard.preSale.order_results_by')}
                  </Typography>
                  <Grid container>
                    <Grid item xs={7}>
                      <FormControl variant="outlined" fullWidth size="small">
                        <InputLabel>
                          {t('i18n.dashboard.preSale.select_the_ordenation')}
                        </InputLabel>
                        <Select
                          defaultValue=""
                          value={filterForm.orderBy || ''}
                          className="small-input"
                          onChange={htmlElemtEvent => {
                            setFilterForm({
                              ...filterForm,
                              orderBy: htmlElemtEvent.target.value
                            });
                          }}
                          label={t('i18n.ticketslistview.STATUS')}
                        >
                          <MenuItem value="">
                            <em>{t('i18n.ticketslistview.NONE')}</em>
                          </MenuItem>
                          {orderByEnum.map(order => {
                            return (
                              <MenuItem value={order.value} key={order.value}>
                                {t(
                                  `i18n.ticketslistview.ticketStatus.${order.value}`
                                )}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                <Box mt={2}>
                  <Typography gutterBottom>
                    {t('i18n.dashboard.preSale.filter_results_by_ads')}
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography
                        gutterBottom
                        variant="h5"
                        style={{ marginTop: '15px' }}
                      >
                        {t('i18n.dashboard.preSale.that_have_a_word')}
                      </Typography>
                      <TextField
                        size="small"
                        value={filterForm.keyword || ''}
                        onChange={htmlElemtEvent => {
                          setFilterForm({
                            ...filterForm,
                            keyword: htmlElemtEvent.target.value
                          });
                          // handleRemoveShortcutFlag();
                        }}
                        fullWidth
                        className={classes.inputSmall}
                        label={t(
                          'i18n.dashboard.preSale.set_the_keyword_or_sentence'
                        )}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Box>
              </FilterWrapper>
              <br />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#fff',
            padding: '15px'
          }}
        >
          <Button
            onClick={() => {
              clearFilterForm();
              // sessionStorage.setItem(
              //   'preSalefilterUseQueryParams',
              //   JSON.stringify(false)
              // );
            }}
            variant="contained"
          >
            {t('i18n.ticketslistview.CLEAR')}
          </Button>
          <Button
            onClick={() => {
              submitFilter(true);
              setOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            {t('i18n.ticketslistview.SEARCH')}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FilterContainer;
