import React from 'react';
import { useTranslation } from 'react-i18next';
import formHelper from '../../../../utils/formHelper';
import { COLORS } from '../../../../styles/settings/colors.config';
import { compare } from '../../services/dashboardServices';
import ReasonsLevelsCard from './ReasonsLevelsCard';

const getReasonsDetails = reasonList => {
  return reasonList
    ?.sort((a, b) => compare(b.qtd, a.qtd))
    ?.map(motivo => {
      return {
        label: motivo.type,
        color: COLORS.blueText,
        value: motivo.qtd,
        divider: false,
        show: true
      };
    });
};

const ReasonsRenderLevelsCard = ({
  dataIndividualDash,
  dataEquipDash,
  isAdmin
}) => {
  const { t } = useTranslation();

  const motivos_n1_equip = dataEquipDash.motivos_n1;
  const motivos_n2_equip = dataEquipDash.motivos_n2;
  const motivos_n1_indiv = dataIndividualDash.motivos_n1;
  const motivos_n2_indiv = dataIndividualDash.motivos_n2;

  const motivos_n1_total_equip = motivos_n1_equip?.reduce(
    (acc, item) => acc + item.qtd,
    0
  );
  const motivos_n2_total_equip = motivos_n2_equip?.reduce(
    (acc, item) => acc + item.qtd,
    0
  );
  const motivos_n1_total_indiv = motivos_n1_indiv?.reduce(
    (acc, item) => acc + item.qtd,
    0
  );
  const motivos_n2_total_indiv = motivos_n2_indiv?.reduce(
    (acc, item) => acc + item.qtd,
    0
  );

  return (
    <ReasonsLevelsCard
      descriptionHint
      headerData
      showMoreDetails
      headerMultipleData={[
        {
          subtitle: 'N1',
          color: COLORS.grayText,
          firstLabel: `${t('i18n.dashboard.components.individual')} N1`,
          secondaryLabel: `${t('i18n.dashboard.components.equip')} N1`,
          firstData: formHelper.addZeroToCompleteTwoDigits(
            motivos_n1_total_indiv
          ),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(
            motivos_n1_total_equip
          ),
          action: true
        },
        {
          subtitle: 'N2',
          color: COLORS.grayText,
          firstLabel: `${t('i18n.dashboard.components.individual')} N2`,
          secondaryLabel: `${t('i18n.dashboard.components.equip')} N2`,
          firstData: formHelper.addZeroToCompleteTwoDigits(
            motivos_n2_total_indiv
          ),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(
            motivos_n2_total_equip
          ),
          action: true
        }
      ]}
      isTeamView={isAdmin}
      extraDataN1={getReasonsDetails(
        isAdmin ? motivos_n1_equip : motivos_n1_indiv
      )}
      extraDataN2={getReasonsDetails(
        isAdmin ? motivos_n2_equip : motivos_n2_indiv
      )}
    />
  );
};

export default ReasonsRenderLevelsCard;
