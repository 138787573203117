import {
  Box,
  Card,
  CardContent,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';
import HeaderTitle from '../../../../components/HeaderTitle';
import userService from '../../../../services/user.service';
import NewTicketSelection from '../../GuideTIcket/NewTicketSelection';
import TicketScheduleIndicator from './TicketScheduleIndicator';
import Grouping from '../../grouping/Grouping';
import filterHelper from '../../../../utils/filterHelper';

import {
  RemoveFilterCardButton,
  cardStyle,
  FilterCardHeader,
  CustomTypographyDescribe,
  typographyStyle
} from '../../../../styles/components/Tags';

const FilterChip = ({
  itemKey,
  index,
  filterData,
  removeFilterFromChip,
  filterFormData
}) => {
  const { t } = useTranslation();
  if (itemKey === 'whatsapp' && filterData[itemKey] === 'true') {
    return (
      <Tooltip key={index} title={t('i18n.ticketslistview.SERVICE_CHANNEL')}>
        <Chip
          style={{
            marginRight: '2px',
            marginBottom: '2px',
            maxWidth: '100px'
          }}
          label="WhatsApp"
          onDelete={() => {
            removeFilterFromChip(itemKey);
          }}
        />
      </Tooltip>
    );
  }

  if (
    itemKey !== 'marketPlace' &&
    itemKey !== 'isRealTime' &&
    itemKey !== 'ticketType' &&
    itemKey !== 'whatsapp' &&
    itemKey !== 'hasIncident' &&
    itemKey !== 'reasonGroup' &&
    !!filterData[itemKey]
  ) {
    return (
      <Box key={index} item="true">
        <Card
          style={{
            borderRadius: '10px',
            maxWidth: '215px'
          }}
        >
          <CardContent style={cardStyle}>
            <FilterCardHeader>
              <Typography variant="overline" style={typographyStyle}>
                {filterFormData[itemKey]?.label === 'ID'
                  ? t(`i18n.ticketslistview.ID`)
                  : t(filterFormData[itemKey]?.label)}
              </Typography>
              <Tooltip title={t('i18n.dashboard.REMOVE_FILTER')}>
                <RemoveFilterCardButton
                  color="primary"
                  aria-label="delete"
                  onClick={() => {
                    removeFilterFromChip(itemKey);
                  }}
                >
                  <CloseIcon />
                </RemoveFilterCardButton>
              </Tooltip>
            </FilterCardHeader>
            <CustomTypographyDescribe>
              {filterHelper.filterChipLabeler(itemKey, filterData[itemKey], t)}
            </CustomTypographyDescribe>
          </CardContent>
        </Card>
      </Box>
    );
  }

  return <></>;
};

const CustomHeader = ({
  refreshTableContent,
  handleSearchAcerStore,
  currentPage,
  filterData,
  removeFilterFromChip,
  filterFormData,
  indicators
}) => {
  return (
    <Box className="custom-header">
      <Grid container>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <HeaderTitle title="Pós-Venda" />
            <TicketScheduleIndicator {...{ filterData, indicators }} />
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {userService.hasRole('ticket_new') && (
                <div>
                  <NewTicketSelection
                    refreshTableContent={refreshTableContent}
                  />
                  <IconButton disabled color="secondary">
                    |
                  </IconButton>
                </div>
              )}
              {handleSearchAcerStore() && (
                <div>
                  <Grouping />
                  <IconButton disabled color="secondary">
                    |
                  </IconButton>
                </div>
              )}

              <IconButton
                color="primary"
                onClick={() => refreshTableContent(currentPage)}
              >
                <RefreshIcon />
              </IconButton>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}
        >
          {Object.entries(filterData).map(([key], index) => (
            <FilterChip
              {...{ index, filterData, removeFilterFromChip, filterFormData }}
              itemKey={key}
              key={Math.random()}
            />
          ))}
        </Grid>
      </Grid>
    </Box>
  );
};

export default CustomHeader;
