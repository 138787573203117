/* eslint-disable no-debugger */
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Forward, Help } from '@material-ui/icons';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SelectMultipleComponent from '../../../components/SelectMultipleComponent';
import {
  useMarketplaceList,
  useMarketplaceListPreSale,
  useReportTypesList
} from '../../../hooks/hooks';
import API from '../../../services/api';
import userService from '../../../services/user.service';
import { COLORS } from '../../../styles/settings/colors.config';
import { defaultDateFormat } from '../services/convertDate';
import { CustomTypograph } from '../styles';

const DateSection = styled.div`
  background-color: #f3f3f3;
  padding: 10px 20px;
`;

const ReportParamsSection = styled.div`
  background-color: white;
  padding: 10px 20px;
  height: 178px;
`;

const CustomReportSection = styled.div`
  background-color: #f3f3f3;
  padding: 10px 20px;
  height: 260px;
`;

const ButtonsSection = styled.div`
  background-color: white;
  padding: 8px 20px;
  display: flex;
  justify-content: space-between;
`;

const useStyles = makeStyles({
  select: {
    width: '300px',
    height: '35px'
  },
  input: {
    '& .MuiOutlinedInput-root': {
      height: '35px'
    },
    backgroundColor: 'white',
    width: '300px'
  },
  checkboxLabel: {
    fontSize: 14
  }
});

const NewReportComponent = ({
  setOpenModal,
  getData,
  pageSize,
  currentPage,
  reportFilter,
  beDuplicated,
  setBeDuplicated
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [marketPlaces, setMarketPlaces] = useState([]);
  const [reportType, setReportType] = useState('');
  const [reportTitle, setReportTitle] = useState('');
  const [selectedMarketplace, setSelectedMarketplace] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blockButton, setBlockButton] = useState(false);
  const [blockDate, setBlockDate] = useState(false);
  const [columnsList, setColumnsList] = useState([]);
  const [selectedColumnsList, setSelectedColumnsList] = useState([]);
  const [disabledSelectedColumns, setDisabledSelectedColumns] = useState(false);
  const [pickedColumns, setPickedColumns] = useState([]);
  const [pickedSelectedColumns, setPickedSelectedColumns] = useState([]);
  const [basedOn, setBasedOn] = useState('');
  const [baseColumnsList, setBaseColumnsList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const reportTypes = useReportTypesList();
  const classes = useStyles();
  const MARKETS_PLACE = useMarketplaceList();
  const MARKETPLACES_PRESALE = useMarketplaceListPreSale();

  const MARKETPLACES_SCORE = ['B2W', /* 'CNOVA' , */ 'MAGALU', 'MERCADO_LIVRE'];
  const MARKETPLACES_COMPOSITION_SCORE = [
    'B2W',
    'MAGALU'
    // 'CNOVA',
    // 'MERCADO_LIVRE'
  ];

  const processDefaultDuplicatedData = () => {
    setReportTitle(beDuplicated.descricao);
    setReportType(beDuplicated.report_type);
    setSelectedMarketplace(beDuplicated.marketplace);
    setBasedOn('abertura');
  };

  const getMarketplaces = (type = null) => {
    let marketPlacesList;

    if (
      !type ||
      type === 'pos-venda' ||
      type === 'pos-venda-custom' ||
      type === 'volumetria-pos' ||
      type === 'pre-venda-offline' ||
      type === 'cancelamento'
    ) {
      let marketplacesHook = MARKETS_PLACE;
      if (type === 'pre-venda-offline') {
        marketplacesHook = MARKETPLACES_PRESALE;
      }

      const filteredValues = marketplacesHook.filter(
        (item) => item.value !== 'All'
      );
      let allMarketplacesString = '';
      filteredValues.forEach((item) => {
        allMarketplacesString += `${item.value} `;
      });

      marketPlacesList = [
        {
          text: `${t('i18n.dashboard.mktplacereport.all_marketplaces')}`,
          value: allMarketplacesString,
          icon: ''
        },
        ...filteredValues
      ];

      if (type && Object.keys(beDuplicated)?.length === 0) {
        setSelectedMarketplace([]);
      }
    } else if (type === 'status-users') {
      setSelectedMarketplace([]);
    } else if (type === 'score_mkt') {
      marketPlacesList = MARKETS_PLACE.filter((mktp) =>
        MARKETPLACES_SCORE.includes(mktp.value)
      );
    } else if (type === 'composition_score_mkt') {
      marketPlacesList = MARKETS_PLACE.filter((mktp) =>
        MARKETPLACES_COMPOSITION_SCORE.includes(mktp.value)
      );
    } else {
      const filteredMarketplaces = MARKETS_PLACE.filter(
        (item) =>
          item.value === 'CUSTOMER_GUIDE' || item.value === 'CUSTOMER_GUIDE_B2B'
      );
      marketPlacesList = filteredMarketplaces;
      setSelectedMarketplace([
        filteredMarketplaces[0]?.value,
        filteredMarketplaces[1]?.value
      ]);
    }

    setMarketPlaces(marketPlacesList);
  };

  const fillColumnsList = (list) => {
    if (list?.length === 0) {
      setDisabledSelectedColumns(true);
      return false;
    }
  };

  const handleChangeMultiple = (event, set) => {
    event.preventDefault();
    event.persist();
    const st = event.target.scrollTop;
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) value.push(options[i].value);
    }
    set(value);
    setTimeout(() => {
      event.target.scrollTop = st;
    }, 0);
    event.target.focus();
  };

  const normalizeSendJSON = () => {
    if (
      !startDate ||
      (reportType !== 'score_mkt' &&
        reportType !== 'composition_score_mkt' &&
        !endDate) ||
      (reportType !== 'status-users' &&
        reportType !== 'cancelamento' &&
        selectedMarketplace.length === 0) ||
      !reportTitle ||
      !reportType
    ) {
      enqueueSnackbar(
        t('i18n.dashboard.mktplacereport.fill_all_fields_warning'),
        {
          variant: 'error'
        }
      );
      setIsLoading(false);
      return false;
    }

    if (
      reportType === 'pos-venda-custom' &&
      (!basedOn || selectedColumnsList.length === 0)
    ) {
      enqueueSnackbar(
        t('i18n.dashboard.mktplacereport.fill_all_fields_warning'),
        {
          variant: 'error'
        }
      );
      setIsLoading(false);
      return false;
    }

    let allMarketplacesString = '';

    const stringify = () =>
      selectedMarketplace?.forEach((item) => {
        allMarketplacesString += `${item} `;
      });

    if (
      reportType !== 'status-users' &&
      reportType !== 'composition_score_mkt' &&
      reportType !== 'score_mkt'
    ) {
      stringify();
    }

    if (reportType === 'composition_score_mkt' || reportType === 'score_mkt') {
      allMarketplacesString += `${selectedMarketplace} `;
    }

    const copiedEndDate = new Date(endDate);
    const copiedStartDate = new Date(startDate);
    copiedStartDate.setHours(3, 0, 0);

    if (reportType !== 'score_mkt' && reportType !== 'composition_score_mkt') {
      copiedEndDate.setDate(copiedEndDate.getDate() + 1);
      copiedEndDate.setHours(2, 59, 59);
    }

    const json = {
      startDate: defaultDateFormat(moment(copiedStartDate)),
      endDate: copiedEndDate ? defaultDateFormat(moment(copiedEndDate)) : '',
      marketplace:
        reportType !== 'status-users' ? allMarketplacesString.trimEnd() : '',
      descricao: reportTitle,
      // eslint-disable-next-line camelcase
      report_type: reportType
    };

    if (
      reportType === 'pre-venda-online-custom' ||
      reportType === 'pos-venda-custom'
    ) {
      json.customFields = selectedColumnsList;
    }

    if (reportType.includes('pre-venda-online')) {
      json.startDate = defaultDateFormat(moment(startDate).startOf('day'));
      json.endDate = defaultDateFormat(moment(endDate).endOf('day'));
      json.marketplace = json.marketplace.replaceAll(
        'CUSTOMER_GUIDE',
        'ACER_STORE'
      );
    }

    if (basedOn) {
      json.dateType = basedOn;
    }

    return json;
  };

  const generateReport = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    const json = normalizeSendJSON();

    if (json) {
      try {
        const result = await API.post(
          `/auth/lambda/reports/execute-report`,
          json
        );

        if (result.status === 200) {
          enqueueSnackbar(t('i18n.dashboard.mktplacereport.report_sended'), {
            variant: 'success'
          });

          setTimeout(() => {
            // const jsonSelect = normalizeJSON();
            getData(reportFilter, currentPage, pageSize);
            setIsLoading(false);
            setOpenModal(false);
            setBeDuplicated({});
          }, 5000);
        } else {
          enqueueSnackbar(
            t('i18n.dashboard.mktplacereport.error_sending_report'),
            {
              variant: 'error'
            }
          );
          setIsLoading(false);
        }
      } catch (error) {
        enqueueSnackbar(
          t('i18n.dashboard.mktplacereport.error_sending_report'),
          {
            variant: 'error'
          }
        );
        setIsLoading(false);
      }
    }
  };

  const clearFields = () => {
    setStartDate('');
    setEndDate('');
    setSelectedMarketplace([]);
    setReportType('');
    setReportTitle('');
  };

  const cancelProcess = () => {
    clearFields();
    setBeDuplicated({});
    setOpenModal(false);
  };

  const getColumnsList = async () => {
    const { data } = await API.get(
      `/auth/lambda/reports/custom-report?report_type=${reportType}`
    );

    setBaseColumnsList(data);
    setColumnsList(
      data.filter((column) => !selectedColumnsList.includes(column))
    );
  };

  const handleIncludeColumns = () => {
    const updatedList = [...selectedColumnsList, ...pickedColumns];
    setSelectedColumnsList(updatedList);

    setDisabledSelectedColumns(false);
    setColumnsList(
      baseColumnsList.filter((column) => !updatedList.includes(column))
    );

    setPickedColumns([]);
  };

  const handleRemoveColumns = () => {
    // Remove all items that aren't in picked
    const updatedList = selectedColumnsList.filter(
      (column) => !pickedSelectedColumns.includes(column)
    );
    setSelectedColumnsList(updatedList);
    setColumnsList(
      baseColumnsList.filter((column) => !updatedList.includes(column))
    );
    setPickedSelectedColumns([]);
  };

  const plus = (a, b) => a + b;
  const minus = (a, b) => a - b;

  const moveColumn = (fn, name) => {
    const auxArray = [...selectedColumnsList];
    const currentIndex = selectedColumnsList
      .map((item, i) => {
        if (pickedSelectedColumns.includes(item.value)) {
          return i === 0 ? '0' : i;
        }
        return false;
      })
      .filter((item) => item);

    if (currentIndex.includes('0') && name === 'minus') return false;

    currentIndex.forEach((item) => {
      const element = auxArray[Number(item)];
      auxArray.splice(item, 1);
      auxArray.splice(fn(item, 1), 0, element);
    });

    setSelectedColumnsList(auxArray);
  };

  const handleBlockDate = (end) => {
    const strtDt = new Date().setDate(new Date().getDate() - 1);

    if (reportType !== 'status-users') {
      setBlockButton(false);
      setBlockDate(false);
    }

    if (end >= strtDt && reportType === 'status-users') {
      setBlockButton(true);
      setBlockDate(true);
    } else {
      setBlockButton(false);
      setBlockDate(false);
    }
  };

  useEffect(() => {
    if (
      reportType === 'pre-venda-online-custom' ||
      reportType === 'pos-venda-custom'
    ) {
      getColumnsList();
    }
    getMarketplaces(reportType);
  }, [reportType]);

  useEffect(() => {
    handleBlockDate(endDate);
  }, [reportType, endDate]);

  useEffect(() => {
    if (beDuplicated) {
      const arrayKeys = Object.keys(beDuplicated);
      if (arrayKeys.length === 0) {
        fillColumnsList(selectedColumnsList);
        return;
      }

      processDefaultDuplicatedData();
      setStartDate(beDuplicated.start_date);
      setEndDate(beDuplicated.end_date);
      setBasedOn(beDuplicated.dateType);
      setSelectedColumnsList(beDuplicated.customFields);
    }
  }, [beDuplicated]);

  return (
    <form onSubmit={(e) => generateReport(e)}>
      <DateSection>
        <Grid container spacing={5}>
          <Grid item xs={6}>
            <CustomTypograph variant="h5">
              {t('i18n.dashboard.mktplacereport.whats_report_type')}
            </CustomTypograph>
            <FormControl variant="outlined" size="small">
              <Select
                value={reportType}
                onChange={(e) => {
                  setReportType(e.target.value);
                }}
                className={classes.select}
                style={{ backgroundColor: 'white' }}
                disabled={Object.keys(beDuplicated).length > 0}
              >
                {reportTypes.map(
                  (rpt) =>
                    userService.hasRole(rpt.role) && (
                      <MenuItem key={Math.random()} value={rpt.value}>
                        {rpt.label}
                      </MenuItem>
                    )
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column">
              <CustomTypograph variant="h5">
                {t('i18n.dashboard.mktplacereport.report_title')}
              </CustomTypograph>
              <TextField
                id="title"
                variant="outlined"
                type="text"
                size="small"
                value={reportTitle}
                className={classes.input}
                onChange={(e) => {
                  setReportTitle(e.target.value);
                }}
                inputProps={{ maxLength: 50 }}
              />

              {reportTitle?.length > 40 && (
                <div
                  style={{
                    height: '20px',
                    fontSize: '11px',
                    color: 'red',
                    fontFamily: 'Roboto'
                  }}
                >
                  {reportTitle?.length} de 50 caracteres preenchidos
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </DateSection>

      <ReportParamsSection>
        <Grid container spacing={5}>
          <Grid
            item
            xs={6}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Box display="flex" alignItems="flex-start" flexDirection="column">
              <CustomTypograph variant="h5">
                {t('i18n.dashboard.mktplacereport.report_period_composition')}
              </CustomTypograph>
              <Typography
                style={{
                  color: COLORS.WarningColorSLA,
                  weight: 'semibold',
                  fontSize: '12px'
                }}
              >
                {`( ${t('i18n.dashboard.START_DATE_ALERT')} )`}
              </Typography>
              <Box display="flex" mt={2} mb={2} alignItems="center">
                <TextField
                  label={
                    reportType !== 'composition_score_mkt'
                      ? t('i18n.dashboard.START_DATE')
                      : t('i18n.dashboard.START_DATE_ONLY')
                  }
                  size="small"
                  id="de"
                  variant="outlined"
                  type="date"
                  required
                  format="dd/MM/yyyy"
                  value={
                    startDate ? moment(startDate).format('YYYY-MM-DD') : ''
                  }
                  onChange={(date) => {
                    const strtDt = new Date('2021-09-30');
                    const endDt = new Date(date.target.value);

                    if (endDt <= strtDt) {
                      setBlockButton(true);
                    } else {
                      setStartDate(
                        new Date(date.target.value.replace(/-/g, '/'))
                      );
                      setBlockButton(false);
                    }
                  }}
                  style={{ marginLeft: '10px', marginRight: '10px' }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
                {reportType !== 'composition_score_mkt' && (
                  <TextField
                    label={t('i18n.dashboard.END_DATE')}
                    id="ate"
                    size="small"
                    variant="outlined"
                    type="date"
                    required={
                      !!(
                        reportType !== 'score_mkt' &&
                        reportType !== 'composition_score_mkt'
                      )
                    }
                    format="dd/MM/yyyy"
                    defaultValue={
                      endDate ? moment(endDate).format('YYYY-MM-DD') : ''
                    }
                    onChange={(date) => {
                      const endDt = new Date(date.target.value);
                      handleBlockDate(endDt);
                      setEndDate(
                        new Date(date.target.value.replace(/-/g, '/'))
                      );
                    }}
                    style={{ marginLeft: '10px' }}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                )}
              </Box>

              {(reportType === 'composition_score_mkt' ||
                reportType === 'score_mkt') && (
                <Typography
                  style={{
                    color: COLORS.WarningColorSLA,
                    weight: 'semibold',
                    fontSize: '12px'
                  }}
                >
                  {t('i18n.dashboard.START_DATE_ALERT_SCORE_REPORT')}
                </Typography>
              )}
              {blockDate && (
                <Typography
                  style={{
                    color: COLORS.WarningColorSLA,
                    weight: 'semibold',
                    fontSize: '12px'
                  }}
                >
                  {t('i18n.dashboard.END_DATE_LIMIT')}
                </Typography>
              )}
            </Box>

            {reportType === 'pos-venda-custom' && (
              <Box display="flex" flexDirection="column" mt={1}>
                <CustomTypograph variant="h5">
                  {t('i18n.dashboard.mktplacereport.date_based_on')}
                </CustomTypograph>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="condicao"
                    name="basedOn"
                    value={basedOn}
                    onChange={(ev) => {
                      setBasedOn(ev.target.value);
                    }}
                  >
                    <Box display="flex" flexDirection="row">
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxLabel
                        }}
                        value="abertura"
                        control={<Radio color="primary" />}
                        label={t('i18n.simplewords.OPENING')}
                      />
                      <FormControlLabel
                        classes={{
                          label: classes.checkboxLabel
                        }}
                        value="fechamento"
                        control={<Radio color="primary" />}
                        label={t('i18n.simplewords.CLOSING')}
                      />
                    </Box>
                  </RadioGroup>
                </FormControl>
              </Box>
            )}
          </Grid>
          {reportType !== 'status-users' && reportType !== 'cancelamento' && (
            <Grid
              item
              xs={6}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start'
              }}
            >
              <CustomTypograph variant="h5">
                {reportType !== 'composition_score_mkt' &&
                reportType !== 'score_mkt'
                  ? t('i18n.dashboard.mktplacereport.select_marketplaces')
                  : t(
                      'i18n.dashboard.mktplacereport.select_marketplaces_one_by_time'
                    )}
              </CustomTypograph>

              {reportType !== 'composition_score_mkt' &&
              reportType !== 'score_mkt' ? (
                <SelectMultipleComponent
                  value={selectedMarketplace}
                  onChange={(e) =>
                    handleChangeMultiple(e, setSelectedMarketplace)
                  }
                  valueList={marketPlaces}
                  size={5}
                />
              ) : (
                <FormControl variant="outlined" fullWidth>
                  <Select
                    value={selectedMarketplace}
                    className={classes.select}
                    style={{ backgroundColor: 'white' }}
                    onChange={(htmlElemtEvent) => {
                      setSelectedMarketplace(htmlElemtEvent.target.value);
                    }}
                  >
                    {marketPlaces?.map((item) => {
                      return (
                        <MenuItem key={item.text} value={item.value}>
                          {item.text}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              )}
            </Grid>
          )}
        </Grid>
      </ReportParamsSection>

      {(reportType === 'pos-venda-custom' ||
        reportType === 'pre-venda-online-custom') && (
        <CustomReportSection>
          <Box display="flex" alignItems="center" mb={2}>
            <CustomTypograph variant="h5" style={{ marginRight: '10px' }}>
              {t('i18n.dashboard.mktplacereport.which_data_label')}
            </CustomTypograph>
            <Tooltip
              title={t('i18n.dashboard.mktplacereport.custom_instructions')}
            >
              <Help fontSize="small" style={{ color: COLORS.greenText }} />
            </Tooltip>
          </Box>
          <Grid container spacing={5}>
            <Grid item xs={4}>
              <CustomTypograph variant="h5">
                {t('i18n.dashboard.mktplacereport.data_extraction_options')}
              </CustomTypograph>
              <SelectMultipleComponent
                value={pickedColumns}
                onChange={(e) => handleChangeMultiple(e, setPickedColumns)}
                valueList={columnsList}
                size={8}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                marginLeft="20px"
              >
                <Forward
                  style={{ color: COLORS.greenText, minHeight: '40px' }}
                  fontSize="large"
                  onClick={handleIncludeColumns}
                />
                <Forward
                  style={{
                    color: COLORS.greenText,
                    transform: 'rotate(180deg)',
                    minHeight: '40px'
                  }}
                  fontSize="large"
                  onClick={handleRemoveColumns}
                />
                <Button
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={() => setPickedColumns([])}
                >
                  {t('i18n.ticketslistview.CLEAR_NO_CAP')}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <CustomTypograph variant="h5">
                {t('i18n.dashboard.mktplacereport.new_report_columns')}
              </CustomTypograph>
              <SelectMultipleComponent
                value={pickedSelectedColumns}
                onChange={(e) =>
                  handleChangeMultiple(e, setPickedSelectedColumns)
                }
                valueList={selectedColumnsList}
                size={8}
                disabled={disabledSelectedColumns}
              />
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <Forward
                  style={{
                    color: COLORS.greenText,
                    transform: 'rotate(270deg)',
                    minHeight: '40px'
                  }}
                  fontSize="large"
                  onClick={() => moveColumn(minus, 'minus')}
                />
                <Forward
                  style={{
                    color: COLORS.greenText,
                    transform: 'rotate(90deg)',
                    minHeight: '40px'
                  }}
                  fontSize="large"
                  onClick={() => moveColumn(plus, 'plus')}
                />
                <Button
                  color="primary"
                  style={{ textTransform: 'none' }}
                  onClick={() => {
                    setPickedSelectedColumns([]);
                    setSelectedColumnsList([]);
                    setColumnsList(baseColumnsList);
                  }}
                >
                  {t('i18n.ticketslistview.CLEAR_NO_CAP')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CustomReportSection>
      )}

      <ButtonsSection>
        <Button
          variant="contained"
          style={{ textTransform: 'none' }}
          onClick={cancelProcess}
        >
          {t('i18n.usercontainer.CANCEL')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={blockButton}
          style={{ textTransform: 'none' }}
          type="submit"
        >
          {isLoading ? (
            <>
              <CircularProgress size={14} style={{ color: 'white' }} />
              <span style={{ marginLeft: '5px' }}>
                {t('i18n.datatable.body.NOMATCH_LOADING')}
              </span>
            </>
          ) : (
            t('i18n.dashboard.mktplacereport.generate_report')
          )}
        </Button>
      </ButtonsSection>
    </form>
  );
};

export default NewReportComponent;
