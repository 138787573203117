import React, { useState, useEffect } from 'react';
import { AppBar, Box, makeStyles, Toolbar } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import config from '../../config';
import userService from '../../services/user.service';
import API from '../../services/api';
import Logo from '../../components/Logo';
import UserInfo from './Tools/UserInfo';
import OnlineAlert from './Tools/OnlineAlert';
import { DrawerMenu } from './drawerMenu';
import { registerStatus } from '../../stores/userStatusStore';
import { FavoriteModal } from './favoritesBar/favoriteModal';
import { FavoritesBar } from './favoritesBar/favoritesBar';

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  formControl: {
    minWidth: 200
  },
  paper: {
    top: '0px !important'
  },
  list: {
    width: 300,
    height: '100%'
  },
  fullList: {
    width: 'auto'
  }
}));

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  let getHealthMonitor;
  let userAlive; // setintervals para checar se o Monitor está sem falhas e enviar um "alive" do usuário logado

  const classes = useStyles();
  const [online, setOnline] = useState({ online: true, severity: 'green' });
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false
  });

  const toggleDrawer = (anchor, open) => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // Verifica se o status retornado pelo Backend é diferente do existente no localStorage e
  // caso positivo atualiza o Backend com o status do localStorage.
  // Isto visa resolver o problema do usuário que fecha abruptamente o Conecta e ao retornar
  // não atualiza o status no BD.
  const checkAndUpdateStatus = statusFromBD => {
    const statusFromLocalStorage = localStorage.getItem('status');
    if (
      statusFromBD &&
      statusFromLocalStorage &&
      statusFromBD !== statusFromLocalStorage
    ) {
      registerStatus(statusFromLocalStorage);
    }
  };

  const handleHealthCheck = () => {
    if (
      window.location.pathname !== '/login' ||
      window.location.pathname !== '/change-password'
    ) {
      API.get(`/auth/lambda/monitor/get-monitor-health`)
        .then(response => {
          setOnline(response?.data);
        })
        .catch(() => {});
    }
  };

  const handleAliveCheck = () => {
    if (
      window.location.pathname !== '/login' &&
      userService.getCompanyName() === config.cognito.ownerCompany
    ) {
      API.post(`/auth/ms-ticket/v1/user-status-ping`)
        .then(response => {
          checkAndUpdateStatus(response?.data?.results[0]?.status);
        })
        .catch(() => {});
    }
  };

  useEffect(() => {
    // handleHealthCheck();
    getHealthMonitor = setInterval(() => {
      handleHealthCheck();
    }, 60000); // 1 minutes
    userAlive = setInterval(() => {
      handleAliveCheck();
    }, 300000); // 5 minutes
    return () => {
      clearInterval(getHealthMonitor);
      clearInterval(userAlive);
    };
  }, []);

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar>
        <Logo />
        <Box flexGrow={1}>
          <FavoritesBar />
        </Box>
        <OnlineAlert online={online} />
        <UserInfo name={userService.getUsername()} />
        <DrawerMenu
          {...{ toggleDrawer, state, classes, getHealthMonitor, userAlive }}
        />
      </Toolbar>
      <FavoriteModal />
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  className: '',
  onMobileNavOpen: () => {}
};

export default TopBar;
