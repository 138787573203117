import React, { useState } from 'react';
import { Button, makeStyles, Tooltip, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  ArrowBackIos,
  ArrowForwardIos,
  FiberManualRecord,
  Lock,
  LockOpen
} from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import ConfirmationModal from '../../../utils/ConfirmationModal';
import timezoneFormat from '../../../utils/timezoneFormat';
import {
  TabWrapper,
  TabDetails,
  TabDetailsFooter,
  TabContent,
  TabFirstMessage,
  TabBody,
  TabDelete,
  CustomerSpan,
  TabMessageFlex,
  TabWrapperDetails,
  TabIconButton,
  CustomDeleteOutlineIcon,
  TabIconButtonWrapper,
  TabQuestionProduct,
  TabImageText,
  MessageProductLink,
  TabLinkEllipsis,
  TabLinkBreak
} from '../styles';
import API from '../../../services/api';
import { COLORS } from '../../../styles/settings/colors.config';
import {
  getProductLink,
  getProductFromBundle
} from '../services/presaleServices';

const TranslateStatus = (t, status) => {
  switch (status) {
    case 'NEW':
      return (
        <span style={{ color: COLORS.greenText }}>{t('i18n.preSale.NEW')}</span>
      );
    case 'REMOVED':
      return <span style={{ color: '#c00' }}>{t('i18n.preSale.REMOVED')}</span>;
    case 'BANNED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.BANNED')}
        </span>
      );
    case 'ANSWERED':
      return (
        <span style={{ color: COLORS.presaleOrange }}>
          {t('i18n.preSale.ANSWERED')}
        </span>
      );
    case 'PAUSED':
      return (
        <span style={{ color: COLORS.btnDangerColor }}>
          {t('i18n.preSale.PAUSED')}
        </span>
      );
    case 'CLOSED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.CLOSED')}
        </span>
      );
    case 'CLOSED_UNANSWERED':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.CLOSED_UNANSWERED')}
        </span>
      );
    case 'UNDER_REVIEW':
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.UNDER_REVIEW')}
        </span>
      );
    default:
      return (
        <span style={{ color: COLORS.greenText }}>
          {t('i18n.newAutomation.NEW')}
        </span>
      );
  }
};

const yourviewsStatus = (t, moderate) => {
  switch (moderate) {
    case 1:
      return (
        <span style={{ color: COLORS.greenText }}>{t('i18n.preSale.NEW')}</span>
      );
    case 2:
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.IGNORED')}
        </span>
      );
    case 3:
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.ANSWERED_PUBLIC')}
        </span>
      );
    case 4:
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.ANSWERED_REMOVED')}
        </span>
      );
    case 5:
      return (
        <span style={{ color: COLORS.grayText }}>
          {t('i18n.preSale.ANSWERED_PRIVATE')}
        </span>
      );
    default:
      return (
        <span style={{ color: COLORS.greenText }}>{t('i18n.preSale.NEW')}</span>
      );
  }
};

const useStyles = makeStyles({
  button: {
    fontSize: '10px',
    margin: '5px'
  }
});

const PublishedInfo = ({ published, moderate, handlePublish, loading }) => {
  const classes = useStyles();
  const statusColor = published ? COLORS.greenText : COLORS.btnDangerColor;

  const getButtonProps = () => {
    const props = { text: 'Publicar', color: COLORS.greenText };
    if (moderate === 1) {
      props.text = 'Ignorar';
      props.color = COLORS.btnDangerColor;
    }

    if (published) {
      props.text = 'Ocultar';
      props.color = COLORS.btnDangerColor;
    }

    return props;
  };

  const buttonProps = getButtonProps();

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      {moderate !== 1 && (
        <>
          <FiberManualRecord
            size="small"
            color={published ? 'primary' : 'secondary'}
          />
          <Typography style={{ color: statusColor, marginRight: '5px' }}>
            <b>{published ? 'Publicada' : 'Não publicada'}</b>
          </Typography>
        </>
      )}
      <Button
        className={classes.button}
        style={{
          color: buttonProps.color,
          border: `1px solid ${buttonProps.color}`
        }}
        size="small"
        variant="outlined"
        onClick={handlePublish}
        disabled={!!loading}
      >
        {loading ? 'Aguarde...' : buttonProps.text}
      </Button>
    </span>
  );
};

const PreSaleTab = ({
  data,
  index,
  activateProtocol,
  setCurrentTicket,
  getData,
  haveMessage,
  openProductDetailsModal
}) => {
  const { t } = useTranslation();
  // const { enqueueSnackbar } = useSnackbar();
  const firstMessage = data?.marketplaceGenericMessages[0];
  const questionMX = timezoneFormat(firstMessage.date).split(' ');
  const questionDate = `${questionMX[0]} às ${questionMX[1]}`;

  const answer = data?.marketplaceGenericMessages[1] || null;
  const answerMX = answer ? timezoneFormat(answer.date).split(' ') : null;
  const answerDate = answerMX ? `${answerMX[0]} às ${answerMX[1]}` : null;
  //  const [submitData, setSubmitData] = useState({});
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState('');
  const [confirmationModalAction, setConfirmationModalAction] = useState();
  const status = data?.marketplaceBundle?.item?.status; // Status anúncio: pausado ou não
  const [loading, setLoading] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const productInfo = getProductFromBundle(data);

  const handleBlockTicket =
    data.ticketStatus === 'CLOSED' ||
    data.ticketStatus === 'BLOCKED' ||
    data.ticketStatus === 'REMOVED'
      ? 'CLOSED'
      : 'NEW';

  const submitBlock = async id => {
    const url = `/auth/ms-ticket/v1/tickets/mercadolivre/block-client/${id}`;
    try {
      await API.post(url);
      getData(true, false);
    } catch (error) {
      enqueueSnackbar(t('i18n.exception.ERROR_ON_BLOCK_CLIENT'), {
        variant: 'error'
      });
    }
  };

  const handleUnblock = async id => {
    const url = `/auth/ms-ticket/v1/tickets/mercadolivre/block-client/${id}`;

    try {
      await API.delete(url);
      getData(true, false);
    } catch (error) {
      enqueueSnackbar(t('i18n.exception.ERROR_ON_UNBLOCK_CLIENT'), {
        variant: 'error'
      });
    }
  };

  const handleBlock = id => {
    setConfirmationModalAction(() => () => {
      submitBlock(id);
    });
    setConfirmationModalMessage(t('i18n.exception.SURE_BLOCK_CLIENT'));
    setConfirmationModalOpen(true);
  };

  const handleDeleteMLMessage = async id => {
    const url = `/auth/ms-ticket/v1/tickets/mercadolivre/delete-question/${id}`;

    API.delete(url)
      .then(() => {
        getData(true, false);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.exception.ERROR_ON_DELETE_QUESTION'), {
          variant: 'error'
        });
      });
  };

  const handleQuestionWithoutAnswer = () => {
    setConfirmationModalAction(() => () => {
      setCurrentTicket({ data, index });
      // setUnsetMessage(false);
    });
    setConfirmationModalMessage(t('i18n.presale.WARNING_LEAVE_MSG'));
    setConfirmationModalOpen(true);
  };

  /*
  const answerModerateType = () => {
    if (data?.marketplaceBundle.answers?.length > 0) {
      if (data?.marketplaceBundle?.answers[0]?.moderate === 3) {
        return t('i18n.simplewords.PUBLIC');
      }
      if (data?.marketplaceBundle?.answers[0]?.moderate === 4) {
        return t('i18n.simplewords.PRIVATE');
      }
      if (data?.marketplaceBundle?.answers[0]?.moderate === 5) {
        return t('i18n.simplewords.REJECTED');
      }
      return '';
    }
    return '';
  };
  */

  const publishOrUnpublishQuestion = async () => {
    setLoading(true);
    const ticketId = data?.id;
    const { isPublic } = data?.marketplaceGenericMessages[0];
    const { moderate } = data?.marketplaceBundle;

    if (moderate === 1) {
      await API.delete(
        `/auth/ms-ticket/v1/tickets/yourviews/hide-question/${ticketId}`
      );
      setLoading(false);
      getData(true, false);
      return false;
    }

    if (isPublic === null || isPublic) {
      await API.delete(
        `/auth/ms-ticket/v1/tickets/yourviews/hide-question/${ticketId}`
      );
      setLoading(false);
      getData(true, false);
      return false;
    }

    await API.patch(
      `/auth/ms-ticket/v1/tickets/yourviews/hide-question/${ticketId}`
    );
    setLoading(false);
    getData(true, false);
  };

  const handlePublish = async () => {
    const publicQuestion =
      data?.marketplaceGenericMessages[0]?.isPublic === null ||
      data?.marketplaceGenericMessages[0]?.isPublic;

    const moderate = data?.marketplaceBundle?.moderate;

    const modalText = () => {
      if (moderate === 1) return 'ignorar';

      if (publicQuestion) return 'ocultar';

      return 'publicar';
    };

    setConfirmationModalAction(() => () => {
      publishOrUnpublishQuestion();
    });
    setConfirmationModalMessage(
      `Tem certeza que deseja ${modalText()} esta pergunta?`
    );
    setConfirmationModalOpen(true);
  };

  const openAnswer = () => {
    if (data.marketPlace === 'MERCADO_LIVRE') {
      if (activateProtocol !== data.id) {
        // eslint-disable-next-line no-unused-expressions
        haveMessage
          ? handleQuestionWithoutAnswer()
          : setCurrentTicket({ data, index });
      }
    }

    if (data.marketPlace === 'YOURVIEWS') {
      if (data.marketplaceBundle.moderate === 1) {
        handleQuestionWithoutAnswer();
      } else {
        setCurrentTicket({ data, index });
      }
    }
  };

  return (
    <>
      <TabWrapper
        blocked={handleBlockTicket}
        className={activateProtocol === data.id && 'activate'}
        active={activateProtocol === data.id}
        id={data.id}
      >
        <TabWrapperDetails
          blocked={handleBlockTicket}
          className={activateProtocol === data.id && 'activate'}
          active={activateProtocol === data.id}
        >
          <div style={{ width: '100%', display: 'table-cell' }}>
            <TabDetails>
              <Typography style={{ padding: '10px 0px' }}>
                Status:{' '}
                <b>
                  {data?.marketPlace === 'YOURVIEWS'
                    ? yourviewsStatus(t, data?.marketplaceBundle?.moderate)
                    : TranslateStatus(t, data?.ticketStatus)}
                </b>
              </Typography>

              {/* {status === 'paused' && (
                <Typography style={{ padding: '10px 0px', color: '#C00' }}>
                  {t('i18n.exception.preSale.ads_paused')}
                </Typography>
              )} */}

              {data.serviceAttendantLogin &&
                answer &&
                data.marketplaceBundle.status !== 'DELETED' && (
                  <Typography>
                    {t('i18n.presale.ANSWERED_BY')}:{' '}
                    <b>{data.serviceAttendantLogin}</b> em:{' '}
                    {answerDate && answerDate}
                  </Typography>
                )}

              {data.serviceAttendantLogin && !answer && (
                <TabDelete>
                  <Typography>
                    {t('i18n.presale.ASSIGNED_BY')}:{' '}
                    <b>{data.serviceAttendantLogin}</b>
                  </Typography>
                </TabDelete>
              )}
            </TabDetails>
            <TabMessageFlex>
              <TabContent>
                <TabFirstMessage>
                  {data.ticketStatus === 'NEW' &&
                    data.marketPlace === 'MERCADO_LIVRE' && (
                      <Tooltip
                        title={t('i18n.presale.REMOVE_PROTOCOL')}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <CustomDeleteOutlineIcon
                          onClick={() => handleDeleteMLMessage(data.id)}
                        />
                      </Tooltip>
                    )}
                  <TabImageText>
                    <img
                      src={
                        productInfo?.picture ||
                        'https://http2.mlstatic.com/resources/frontend/statics/img-not-available/1.1.0/O.gif'
                      }
                      style={{ maxWidth: '60px' }}
                      alt="Ml"
                    />
                    <TabQuestionProduct>
                      <Tooltip
                        title={productInfo?.title}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <Typography>
                          <MessageProductLink
                            target="_blank"
                            href={getProductLink(productInfo, data.marketPlace)}
                            noreferrer
                          >
                            <TabLinkEllipsis>
                              {productInfo?.title}
                            </TabLinkEllipsis>
                          </MessageProductLink>
                        </Typography>
                      </Tooltip>
                      <Typography style={{ wordBreak: 'break-all' }}>
                        {firstMessage.message}
                      </Typography>
                    </TabQuestionProduct>
                  </TabImageText>
                </TabFirstMessage>
                <TabBody>
                  <Tooltip
                    title={t('i18n.ticketcontainer.RA_DATE')}
                    PopperProps={{
                      disablePortal: true,
                      popperOptions: {
                        positionFixed: true,
                        modifiers: {
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'window'
                          }
                        }
                      }
                    }}
                  >
                    <Typography>{questionDate}</Typography>
                  </Tooltip>
                  <Tooltip
                    title={t('i18n.ticketcontainer.PROTOCOL')}
                    PopperProps={{
                      disablePortal: true,
                      popperOptions: {
                        positionFixed: true,
                        modifiers: {
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'window'
                          }
                        }
                      }
                    }}
                  >
                    <p>
                      {t('i18n.ticketcontainer.PROTOCOL')}: <b>{data.id}</b>
                    </p>
                  </Tooltip>

                  <CustomerSpan>
                    {data.marketplaceBundle.user.blocked ? (
                      <Tooltip
                        title={t('i18n.presale.UNBLOCK_USER')}
                        PopperProps={{
                          disablePortal: true,
                          popperOptions: {
                            positionFixed: true,
                            modifiers: {
                              preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window'
                              }
                            }
                          }
                        }}
                      >
                        <Lock
                          fontSize="small"
                          onClick={() => {
                            handleUnblock(data.id);
                          }}
                          style={{
                            cursor: 'pointer',
                            color: 'red',
                            marginRight: '1px',
                            fontSize: '22px'
                          }}
                        />
                      </Tooltip>
                    ) : (
                      data.marketPlace === 'MERCADO_LIVRE' && (
                        <Tooltip
                          title={t('i18n.presale.BLOCK_USER')}
                          PopperProps={{
                            disablePortal: true,
                            popperOptions: {
                              positionFixed: true,
                              modifiers: {
                                preventOverflow: {
                                  enabled: true,
                                  boundariesElement: 'window'
                                }
                              }
                            }
                          }}
                        >
                          <LockOpen
                            style={{
                              marginRight: '1px',
                              fontSize: '22px',
                              color: COLORS.grayText,
                              cursor: 'pointer'
                            }}
                            fontSize="small"
                            onClick={() => {
                              handleBlock(data.id);
                            }}
                          />
                        </Tooltip>
                      )
                    )}
                    <Tooltip
                      title={t('i18n.presale.INFOS')}
                      PopperProps={{
                        disablePortal: true,
                        popperOptions: {
                          positionFixed: true,
                          modifiers: {
                            preventOverflow: {
                              enabled: true,
                              boundariesElement: 'window'
                            }
                          }
                        }
                      }}
                    >
                      <TabLinkBreak
                        onClick={() => openProductDetailsModal(true, data)}
                      >
                        {data.customerName}
                      </TabLinkBreak>
                    </Tooltip>
                  </CustomerSpan>
                </TabBody>
              </TabContent>
            </TabMessageFlex>
            {data.marketPlace === 'YOURVIEWS' && (
              <TabDetailsFooter>
                <Typography style={{ padding: '10px 0px' }}>
                  {t('i18n.presale.ACER_ID')}:{' '}
                  <b>{data?.marketplaceBundle?.id}</b>
                </Typography>
                {data?.marketplaceBundle?.moderate !== 2 && (
                  <PublishedInfo
                    published={
                      data?.marketplaceGenericMessages[0]?.isPublic === null
                        ? true
                        : data?.marketplaceGenericMessages[0]?.isPublic
                    }
                    moderate={data?.marketplaceBundle?.moderate}
                    {...{ handlePublish, loading }}
                  />
                )}
                {/* answer && (
                  <Typography style={{ padding: '10px 0px' }}>
                    {`${t('i18n.knowledge.QUESTION')}: `}
                    <b style={{ color: COLORS.presaleOrange }}>
                      {answerModerateType()}
                    </b>
                  </Typography>
                ) */}
              </TabDetailsFooter>
            )}
          </div>
          <div
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              minWidth: '15px'
            }}
          >
            {((data.marketPlace === 'MERCADO_LIVRE' && status !== 'paused') ||
              (data.marketPlace === 'YOURVIEWS' &&
                data.marketplaceBundle.moderate !== 2)) && (
              <TabIconButton
                color="primary"
                onClick={() => openAnswer()}
                disabled
                size="medium"
              >
                {((data.marketPlace === 'MERCADO_LIVRE' &&
                  (data.ticketStatus === 'NEW' ||
                    data.ticketStatus === 'ANSWERED')) ||
                  data.marketPlace === 'YOURVIEWS') && (
                  <TabIconButtonWrapper>
                    {activateProtocol === data.id ? (
                      <ArrowBackIos style={{ marginLeft: '4px' }} />
                    ) : (
                      <ArrowForwardIos />
                    )}
                  </TabIconButtonWrapper>
                )}
              </TabIconButton>
            )}
          </div>
        </TabWrapperDetails>
      </TabWrapper>
      <ConfirmationModal
        title={t('i18n.simplewords.WARNING')}
        message={confirmationModalMessage}
        submitConfirmation={() => {
          confirmationModalAction();
          setConfirmationModalOpen(false);
        }}
        openState={confirmationModalOpen}
        closeParent={() => {
          setConfirmationModalOpen(false);
        }}
      />
    </>
  );
};

export default PreSaleTab;
