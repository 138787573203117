import {
  Card,
  Grid,
  Paper,
  Tab,
  Tabs,
  TextField,
  makeStyles,
  Tooltip,
  FormControl,
  Select,
  Box,
  Typography
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  FileCopy,
  QueryBuilder,
  WhatsApp,
  InfoOutlined
} from '@material-ui/icons';
import RichTooltip from '../../../../components/RichTooltip';
import API from '../../../../services/api';
// import { emojiSentiments } from '../../../../utils/emojiSentiments';
import tableHelper from '../../../../utils/tableHelper';
import timezoneFormat from '../../../../utils/timezoneFormat';
import B2wConfirmationModal from '../ModalTicketsDetail/B2wConfirmationModal';
import {
  CardContainer,
  CardHeader,
  CardHeaderContent,
  CardNoShadowNoBackground,
  CardText,
  CardWrapper,
  CustomTabButton
} from './SectionTabs';
import TicketMLBundle from './TicketMLBundle';
import Timeline from '../Cancelling/Views/Timeline/Index';
import RequestDialog from '../Cancelling/Dialogs/RequestDialog';
import reasonList from '../../../../utils/reasonList';
import { COLORS } from '../../../../styles/settings/colors.config';
import userService from '../../../../services/user.service';
import ScheduleInfo from '../../messages/components/schedule/ScheduleInfo';
import { TicketContainerContext } from '../context';
import ScheduleWindow from '../../messages/components/schedule/ScheduleWindow';
import {
  getScheduleIconColor,
  getScheduleStatus
} from '../../messages/components/schedule/functions';

const useStyles = makeStyles(() => ({
  reasonIcon: {
    fontSize: '1.2em',
    marginLeft: '15px',
    cursor: 'pointer',
    color: 'gray'
  },
  inputRoot: {
    height: '30px',
    fontSize: '12px',
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      // Default left padding is 6px
      padding: 2
    }
  },
  inputRootRed: {
    color: COLORS.btnDangerColor,
    fontWeight: 'bolder'
  },
  option: {
    fontSize: '12px',
    marginBottom: '-12px'
  },
  inputReason: {
    height: '30px',
    fontSize: 'inherit'
  },
  inputSmall: {
    width: '220px',
    height: '30px',
    fontSize: '12px',
    backgroundColor: 'white',
    maxLength: '35'
  },
  inputComment: {
    width: '242px',
    height: '30px',
    fontSize: '12px',
    backgroundColor: 'white',
    maxLength: '35'
  },
  scheduleIconRed: {
    '& .MuiSvgIcon-root': {
      fontSize: '14px !important',
      marginRight: '3px',
      color: COLORS.btnDangerColor
    }
  },
  scheduleIconWarning: {
    '& .MuiSvgIcon-root': {
      fontSize: '14px !important',
      marginRight: '3px',
      color: COLORS.WarningColorSLA
    }
  },
  scheduleIconBlue: {
    '& .MuiSvgIcon-root': {
      fontSize: '14px !important',
      marginRight: '3px',
      color: COLORS.blueText
    }
  }
}));

// class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-outlined"
function TabPanel({ children, value, index, header, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
      className={`customTabs ${
        header ? 'customTabsAjustHeader' : 'customTabsAdjust'
      }`}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

const alertSLA = slaMinutes => {
  const slaAlertLimit = tableHelper.TOTAL_MINUTES_ONE_DAY;
  const slaWarningLimit = tableHelper.TOTAL_MINUTES_HALF_DAY;
  if (slaMinutes >= slaAlertLimit) {
    return 'alert';
  }
  if (slaMinutes >= slaWarningLimit) {
    return 'warning';
  }
  return 'green';
};

const ReasonDetail = ({ reason, handleReasonDetailBlur }) => {
  const classes = useStyles();
  const [localReason, setLocalReason] = useState(reason);
  const handleReasonDetailText = value => {
    const newReason = {
      group: localReason.group,
      name: localReason.name,
      desc: value,
      final: true
    };
    setLocalReason(newReason);
  };

  return (
    <TextField
      size="small"
      variant="outlined"
      value={localReason.desc}
      onChange={httmlElemtEvent => {
        handleReasonDetailText(httmlElemtEvent.target.value);
      }}
      onBlur={() => handleReasonDetailBlur(localReason)}
      InputProps={{
        className: classes.inputSmall
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ maxLength: 30 }}
      helperText={`${localReason.desc.length}/30`}
    />
  );
};

const ReasonComment = ({ reason, handleReasonDetailBlur }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [localReason, setLocalReason] = useState(reason);
  const handleReasonDetailText = value => {
    const newReason = {
      group: localReason.group,
      name: localReason.name,
      desc: localReason.desc,
      comment: value,
      final: true
    };
    setLocalReason(newReason);
  };

  useEffect(() => {
    const newReason = {
      group: reason.group || '',
      name: reason.name || '',
      desc: reason.desc || '',
      comment: reason.comment || '',
      final: false
    };
    setLocalReason(newReason);
  }, [reason]);

  return (
    <TextField
      size="small"
      variant="outlined"
      value={localReason.comment || ''}
      onChange={httmlElemtEvent => {
        handleReasonDetailText(httmlElemtEvent.target.value);
      }}
      onBlur={() => handleReasonDetailBlur(localReason)}
      InputProps={{
        className: classes.inputComment
      }}
      // eslint-disable-next-line react/jsx-no-duplicate-props
      inputProps={{ maxLength: 30 }}
      helperText={`${t('i18n.legalprotocol.COMMENTS')}: ${localReason.comment
        ?.length || 0}/30`}
    />
  );
};

const ScheduleHeaderSection = ({
  agendamento,
  editingSchedule,
  setScheduleOpen,
  data,
  setEditingSchedule,
  scheduleOpen
}) => {
  return (
    <>
      {agendamento && (
        <RichTooltip
          content={
            !editingSchedule ? (
              <ScheduleInfo
                closePopover={() => setScheduleOpen(false)}
                schedule={agendamento}
                chat={data}
                editing={editingSchedule}
                setEditing={setEditingSchedule}
              />
            ) : (
              <ScheduleWindow
                closePopover={() => setScheduleOpen(false)}
                schedule={agendamento}
                chat={data}
                editing={editingSchedule}
              />
            )
          }
          open={scheduleOpen}
          placement="bottom"
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer'
            }}
            aria-hidden="true"
            onClick={() => setScheduleOpen(!scheduleOpen)}
          >
            <span>
              <QueryBuilder
                style={{
                  color: getScheduleIconColor(agendamento?.status),
                  fontSize: '14px',
                  marginRight: '5px'
                }}
              />
            </span>
            {getScheduleStatus(agendamento?.status)}
          </span>
        </RichTooltip>
      )}
    </>
  );
};

const TicketHeader = ({
  dataOrder,
  data,
  attendantsList,
  statusList,
  updateTicketStatus,
  ticketStatus,
  attendant,
  marketplaceBundle,
  updateAttendantAnswerToBeInputed,
  setReason,
  reason,
  reasonRequiresComment,
  setOpenHistoric
}) => {
  const makeSubjectField = subject => {
    if (subject === 'WHATSAPP') {
      return (
        <Box display="flex" alignItems="center">
          <span>Pergunta de pós-venda</span>
          <WhatsApp
            style={{
              color: COLORS.greenText,
              fontSize: '16px',
              marginLeft: '7px'
            }}
          />
        </Box>
      );
    }
    return subject;
  };

  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { countTicketsTotal } = useContext(TicketContainerContext);

  const [tabValue, setTabValue] = useState('tabTicketInformation');
  const [openCancelRequest, setOpenCancelRequest] = useState(false);
  const [authorizeDevolution, setAuthorizeDevolution] = useState(false);
  const [openDevolutionConference, setOpenDevolutionConference] = useState(
    false
  );
  const [cancellingViewMode, setCancellingViewMode] = useState(false);
  const [currentCancelProcess, setCurrentCancelProcess] = useState('');
  const [updateTracking, setUpdateTracking] = useState(false);
  const [reloadPending, setReloadPending] = useState(false);
  const [scheduleOpen, setScheduleOpen] = useState(false);

  const ticketId = data.id;
  const date = data.creationDate;
  const sla = data.sla_hoursSinceCreation;
  const subject = makeSubjectField(data?.subject);
  const hasBorder =
    data.sla_hoursSinceCreation > tableHelper.TOTAL_MINUTES_TWO_DAYS;
  const [confirmModalOpenState, setConfirmModalOpenState] = useState(false);
  const [eventStatus, setEventStatus] = useState('');
  const [newStatus, setNewStatus] = useState('');

  const hasCancellingAction = !!data.cancellationProcess;

  const showHeaderDetails =
    data?.marketplaceBundle?.idProtocoloCNOVA ||
    data?.marketplaceBundle?.protocol ||
    data?.marketPlace === 'NETSHOES' ||
    data?.marketPlace === 'VIVO' ||
    data?.marketplaceBundle?.prot ||
    data?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity ||
    data?.marketplaceBundle?.orders?.length > 0;

  const isTicketAFather = () => {
    return data?.dependentTickets?.length > 0;
  };

  const isGroupedTickets = isTicketAFather() || data.mainTicket;

  const mainId = isTicketAFather() ? data.id : data.mainTicket;
  const [dependentChildren, setDependentChildren] = useState([]);

  const [componentLoaded, setComponentLoaded] = useState(false);

  const getTicketInfoByUsername = async (event, newValue) => {
    await API.get(`/auth/ms-ticket/v1/tickets/${ticketId}/${newValue.name}`);
    // attendant = newValue.name;
    // document.activeElement.blur();
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const copyProtocol = label => {
    navigator.clipboard.writeText(label);
    enqueueSnackbar(`Texto copiado: ${label}`, { variant: 'success' });
  };

  const ticketLabel = (id, label) => {
    return (
      <>
        <p
          style={{ fontSize: '11px', textTransform: 'none', textAlign: 'left' }}
          id="protocol"
        >
          {label}
        </p>
        <p>
          {id}{' '}
          <Tooltip
            placement="top"
            title={t('i18n.ticketcontainer.HINT_COPY_PROTOCOL')}
          >
            <FileCopy
              style={{ fontSize: '14px', color: '#cccccc' }}
              onClick={() => copyProtocol(id)}
            />
          </Tooltip>
          {/* {'  '}
          {(data.sentimentAnalysisScore &&
            emojiSentiments[data.sentimentAnalysisScore - 1].emoji) ||
            t('i18n.customerinfo.NOT_PROVIDED')} */}
        </p>
      </>
    );
  };

  const [reasonDetail, setReasonDetail] = useState([]);

  const handleReasonChange = value => {
    const [group, name] = value.split(',');
    const newReason = {
      group,
      name,
      desc: '',
      comment: ''
    };
    setReason(newReason);
    const reasonDetailList = reasonList().find(
      el => el.group === group && el.name === name
    );
    const details = reasonDetailList.detail.map(item => {
      return item.name;
    });
    setReasonDetail(details || []);
  };

  const handleReasonDetailSelect = value => {
    const newReason = {
      group: reason.group,
      name: reason.name,
      desc: value,
      final: !reasonRequiresComment(value)
    };
    setReason(newReason);
  };

  const postReason = async () => {
    try {
      await API.post(`/auth/ms-ticket/v1/tickets/${ticketId}/reason`, reason);
      document.activeElement.blur();
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
    }
  };

  const handleReasonDetailBlur = localReason => {
    setReason(localReason);
  };

  useEffect(() => {
    if (!componentLoaded && isGroupedTickets) {
      API.get(`/auth/ms-ticket/v1/tickets/grouping/${mainId}/`).then(result => {
        setDependentChildren(result.data);
      });
      setComponentLoaded(true);
    }
  }, []);

  useEffect(() => {
    const foundDetail = reasonList().find(
      el => el.group === reason.group && el.name === reason.name
    );
    let details = [];
    if (foundDetail) {
      details = foundDetail.detail.map(item => {
        return item.name;
      });
    }
    setReasonDetail(details);
    if (reason.final && reason.desc.trim().length > 0) {
      postReason();
    }
  }, [reason]);

  const agendamento = data?.protocolSchedulingResource;
  const [editingSchedule, setEditingSchedule] = useState(false);

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          {countTicketsTotal > 1 && (
            <CustomTabButton
              value="tabTicketInformation"
              label={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                  }}
                >
                  <InfoOutlined />
                  <Typography style={{ textTransform: 'none' }}>
                    Este CPF possui outros protocolos. Visualize no
                  </Typography>
                  <div
                    style={{
                      color: COLORS.greenText,
                      border: `1px solid ${COLORS.greenText}`,
                      padding: '0 5px 0 5px',
                      textTransform: 'none'
                    }}
                  >
                    {t('i18n.ticketcontainer.protocol_historic')}
                  </div>
                </div>
              }
              onClick={() => setOpenHistoric(true)}
            />
          )}
          <CardNoShadowNoBackground className="cardHeader customTabBorderAdjust">
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {!isGroupedTickets && (
                <Tab
                  value="tabTicketInformation"
                  label={ticketLabel(
                    ticketId,
                    t('i18n.ticketcontainer.PROTOCOL')
                  )}
                />
              )}
              {isGroupedTickets && (
                <Tab
                  onClick={() => {
                    history.push(`/ticket-container/${mainId}`);
                  }}
                  value={
                    String(data.id) === String(mainId)
                      ? 'tabTicketInformation'
                      : '-'
                  }
                  label={ticketLabel(
                    mainId,
                    t('i18n.ticketcontainer.MAIN_PROTOCOL')
                  )}
                />
              )}
              {isGroupedTickets &&
                dependentChildren.map(item => {
                  return (
                    <Tab
                      onClick={() => {
                        history.push(`/ticket-container/${item}`);
                      }}
                      key={item}
                      value={
                        String(data.id) === String(item)
                          ? 'tabTicketInformation'
                          : '-'
                      }
                      label={ticketLabel(
                        item,
                        t('i18n.ticketcontainer.DEPENDENT_PROTOCOL')
                      )}
                    />
                  );
                })}
            </Tabs>
            <TabPanel value={tabValue} index="tabTicketInformation" header>
              <Paper>
                <Card>
                  {reloadPending && !hasCancellingAction && (
                    <Alert severity="warning">
                      A solicitação foi criada, mas necessita de uma atualização
                      da tela para exibir a timeline. Termine de enviar as
                      mensagens para o cliente e atualize esta tela para exibir.
                    </Alert>
                  )}
                  {hasCancellingAction && (
                    <Timeline
                      {...{
                        data,
                        setOpenDevolutionConference,
                        setAuthorizeDevolution,
                        setCurrentCancelProcess,
                        setUpdateTracking,
                        setCancellingViewMode
                      }}
                    />
                  )}
                  <CardContainer
                    border={hasBorder.toString()}
                    container
                    style={{ borderRight: '1px transparent' }}
                  >
                    <CardWrapper item xs={1} lg={1}>
                      <CardHeader>
                        {t('i18n.ticketslistview.OPENING_DATE')}
                      </CardHeader>
                      <CardHeaderContent>
                        <CardText>{`${timezoneFormat(date)} - ${
                          tableHelper.timeDiffCalc(date).difference
                        }`}</CardText>
                        {/* <CardText>
                          {tableHelper.timeDiffCalc(date).difference}
                        </CardText> */}
                      </CardHeaderContent>
                    </CardWrapper>
                    <CardWrapper item xs={1} lg={1}>
                      <CardHeader>SLA</CardHeader>
                      <CardHeaderContent>
                        <CardText alert={alertSLA(sla)}>
                          {tableHelper.formatMinutes(sla, 'protocol')}
                        </CardText>
                      </CardHeaderContent>
                    </CardWrapper>

                    <CardWrapper item xs={2} lg={2}>
                      <CardHeader>
                        <span
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span>Status</span>
                          <ScheduleHeaderSection
                            {...{
                              agendamento,
                              editingSchedule,
                              setScheduleOpen,
                              data,
                              setEditingSchedule,
                              scheduleOpen
                            }}
                          />
                        </span>
                      </CardHeader>
                      <CardHeaderContent>
                        {ticketStatus !== 'CLOSED' ? (
                          <Autocomplete
                            disableClearable
                            classes={{
                              inputRoot: classes.inputRoot,
                              option: classes.option
                            }}
                            options={statusList}
                            noOptionsText={t(
                              'i18n.ticketcontainer.STATUS_NO_OPTIONS'
                            )}
                            getOptionLabel={option =>
                              t(`i18n.ticketslistview.ticketStatus.${option}`)
                                ? t(
                                    `i18n.ticketslistview.ticketStatus.${option}`
                                  )
                                : ''
                            }
                            renderInput={params => (
                              <TextField {...params} variant="outlined" />
                            )}
                            value={ticketStatus}
                            onChange={(event, newValue) => {
                              if (
                                data.marketPlace === 'B2W' &&
                                newValue === 'RESOLVED'
                              ) {
                                setEventStatus(event);
                                setNewStatus(newValue);
                                setConfirmModalOpenState(true);
                                return;
                              }
                              updateTicketStatus(event, newValue);
                            }}
                          />
                        ) : (
                          <CardText>
                            {' '}
                            {t('i18n.ticketcontainer.CLOSED')}{' '}
                          </CardText>
                        )}
                      </CardHeaderContent>
                    </CardWrapper>
                    {/* )} */}

                    <CardWrapper item xs={2} lg={2}>
                      <CardHeader>
                        {t('i18n.ticketcontainer.ESPECIALIST')}
                        {/* Especialista */}
                      </CardHeader>
                      <CardHeaderContent>
                        {ticketStatus !== 'CLOSED' &&
                        userService.hasRole('ticket_assign_all') ? (
                          <Autocomplete
                            disableClearable
                            classes={{
                              inputRoot: classes.inputRoot,
                              option: classes.option
                            }}
                            inputprops={{ padding: 0 }}
                            options={attendantsList}
                            // disabled={!userService.hasRole('ticket_assign_all')}
                            noOptionsText={t(
                              'i18n.ticketcontainer.STATUS_NO_OPTIONS'
                            )}
                            getOptionLabel={option =>
                              option?.name ? option.name : option || ''
                            }
                            renderInput={params => (
                              <TextField {...params} variant="outlined" />
                            )}
                            value={attendant}
                            onChange={(event, newValue) => {
                              getTicketInfoByUsername(event, newValue);
                            }}
                          />
                        ) : (
                          <CardText> {attendant} </CardText>
                        )}
                      </CardHeaderContent>
                    </CardWrapper>
                    <CardWrapper item xs={1} lg={1}>
                      <CardHeader>
                        {t('i18n.ticketcontainer.SUBJECT')}
                      </CardHeader>
                      <CardHeaderContent>
                        <CardText>{subject}</CardText>
                      </CardHeaderContent>
                    </CardWrapper>
                    {/* Motivo */}
                    <CardWrapper item xs={2} lg={2}>
                      <CardHeader redAlert={!reason.name}>
                        {t('i18n.ticketcontainer.REASON')}
                      </CardHeader>
                      <CardHeaderContent>
                        {/* Comentado temporariamente - NÃO REMOVA */}
                        {/* {ticketStatus !== 'CLOSED' ? ( */}
                        <FormControl variant="outlined" fullWidth size="small">
                          <Select
                            native
                            className={classes.inputReason}
                            value={
                              reason.name
                                ? `${reason.group},${reason.name}`
                                : ''
                            }
                            onChange={event => {
                              handleReasonChange(event.target.value);
                            }}
                          >
                            <option disabled value="">
                              {t('i18n.simplephrases.SELECT_REASON')}
                            </option>
                            <optgroup label="N1">
                              {reasonList()
                                .filter(el => el.group === 'N1')
                                .map(el => {
                                  return (
                                    <option
                                      key={`${el.group}${el.name}`}
                                      value={`${el.group},${el.name}`}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                            </optgroup>
                            <optgroup label="N2">
                              {reasonList()
                                .filter(el => el.group === 'N2')
                                .map(el => {
                                  return (
                                    <option
                                      key={`${el.group}${el.name}`}
                                      value={`${el.group},${el.name}`}
                                    >
                                      {el.name}
                                    </option>
                                  );
                                })}
                            </optgroup>
                          </Select>
                        </FormControl>
                        {/* Comentado temporariamente - NÃO REMOVA */}
                        {/* ) : (
                          <CardText> {reason.name} </CardText>
                        )} */}
                      </CardHeaderContent>
                    </CardWrapper>
                    {/* Detalhe do Motivo */}
                    {reason?.name && reason.name !== 'Outros' && (
                      <CardWrapper
                        item
                        container
                        direction="column"
                        xs={2}
                        lg={2}
                      >
                        <Grid item>
                          <CardHeader
                            redAlert={
                              !reason.desc ||
                              (reasonRequiresComment(reason.desc) &&
                                !reason.comment)
                            }
                          >
                            {t('i18n.ticketcontainer.REASON_DETAIL')}
                          </CardHeader>
                          <CardHeaderContent>
                            {/* Comentado temporariamente - NÃO REMOVA */}
                            {/* {ticketStatus !== 'CLOSED' ? ( */}
                            <FormControl
                              variant="outlined"
                              fullWidth
                              size="small"
                            >
                              <Select
                                native
                                className={classes.inputReason}
                                value={reason.desc}
                                onChange={event => {
                                  handleReasonDetailSelect(event.target.value);
                                }}
                              >
                                <option disabled value="">
                                  {t('i18n.simplephrases.SELECT_REASON_DETAIL')}
                                </option>
                                {reasonDetail.map((el, index) => {
                                  return (
                                    // eslint-disable-next-line react/no-array-index-key
                                    <option value={el} key={index}>
                                      {el}
                                    </option>
                                  );
                                })}
                              </Select>

                              {/* ) : (
                          <CardText> {reason.desc} </CardText>
                        )} */}
                            </FormControl>
                          </CardHeaderContent>
                        </Grid>
                        <Grid item>
                          {reasonRequiresComment(reason.desc) && (
                            <CardHeaderContent>
                              <ReasonComment
                                reason={reason}
                                handleReasonDetailBlur={handleReasonDetailBlur}
                              />
                            </CardHeaderContent>
                          )}
                        </Grid>
                      </CardWrapper>
                    )}
                    {reason?.name && reason.name === 'Outros' && (
                      <CardWrapper item xs={2} lg={2}>
                        <CardHeader redAlert={reason.desc.trim().length === 0}>
                          {t('i18n.ticketcontainer.REASON_DETAIL')}
                        </CardHeader>
                        <CardHeaderContent>
                          {/* Comentado temporariamente - NÃO REMOVA */}
                          {/* {ticketStatus !== 'CLOSED' ? ( */}
                          <ReasonDetail
                            reason={reason}
                            handleReasonDetailBlur={handleReasonDetailBlur}
                          />
                          {/* ) : (
                          <CardText> {reason.desc} </CardText>
                        )} */}
                        </CardHeaderContent>
                      </CardWrapper>
                    )}
                  </CardContainer>
                  {showHeaderDetails && (
                    <CardContainer container>
                      <TicketMLBundle
                        marketplaceBundle={marketplaceBundle}
                        data={data}
                      />
                    </CardContainer>
                  )}
                </Card>
              </Paper>
            </TabPanel>
          </CardNoShadowNoBackground>
        </Grid>
      </Grid>
      {/* <ModalTicketsDetailReclameAqui
        openState={open}
        closeParent={() => setOpen(false)}
        data={RATicketData}
        linkRATicket={() => {}}
        hasAction
      /> */}
      <B2wConfirmationModal
        openState={confirmModalOpenState}
        closeParent={() => {
          setConfirmModalOpenState(false);
        }}
        submitConfirmation={() => {
          updateTicketStatus(eventStatus, newStatus);
          setConfirmModalOpenState(false);
        }}
      />
      <RequestDialog
        {...{
          dataOrder,
          reason,
          data,
          updateAttendantAnswerToBeInputed,
          currentCancelProcess,
          setReloadPending
        }}
        open={openCancelRequest}
        setOpen={setOpenCancelRequest}
        type="firstLevel"
        title="Solicitar Cancelamento"
      />
      <RequestDialog
        open={openDevolutionConference}
        setOpen={setOpenDevolutionConference}
        type="especialist"
        title="Cancelamento - Conferência de dados - Especialista"
        data={data}
        authorizeDevolution={authorizeDevolution}
        updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
        currentCancelProcess={currentCancelProcess}
      />
      <RequestDialog
        open={updateTracking}
        setOpen={setUpdateTracking}
        type="tracking"
        title="Atualização de Rastreamento"
        data={data}
        updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
        currentCancelProcess={currentCancelProcess}
      />
      <RequestDialog
        open={cancellingViewMode}
        setOpen={setCancellingViewMode}
        type="view"
        title={`${data?.cancellationProcess?.cancellationType} - Visualização de dados`}
        data={data}
        updateAttendantAnswerToBeInputed={updateAttendantAnswerToBeInputed}
        currentCancelProcess={currentCancelProcess}
      />
    </>
  );
};

export default TicketHeader;
