/* eslint-disable no-console */
import {
  Box,
  Button,
  Grid,
  ThemeProvider,
  Typography
} from '@material-ui/core';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSnackbar } from 'notistack';

import marketPlacesEnum from '../../../enums/marketPlaces.enum';
import API from '../../../services/api';
import './styles.css';

import FilterAttendants from './components/FilterAttendants';

import { inputStyles, theme } from '../../../components/inputStyle';

import { haveB2BOnFilter } from '../../../utils/b2bHelper';
import B2WLegalProcess from './FilterContent/B2WLegalProcess';
import MLClaim from './FilterContent/MLClaim';
import SLA from './FilterContent/SLA';
// import Scheduling from './FilterContent/Scheduling';
import reasonList from '../../../utils/reasonList';
import B2WStatus from './FilterContent/B2WStatus';
import Document from './FilterContent/Document';
import FilterReasonGroups from './FilterContent/FilterReasonGroups';
import FilterSubReason from './FilterContent/FilterSubReason';
import Incidents from './FilterContent/Incidents';
import LikeAndDislike from './FilterContent/LikeAndDislike';
import MagaluClaim from './FilterContent/MagaluClaim';
import MarketplaceTicket from './FilterContent/MarketplaceTicket';
import Mediated from './FilterContent/Mediated';
import OpeningDate from './FilterContent/OpeningDate';
import Pedecom from './FilterContent/Pedecom';
import ReclameAquiRelated from './FilterContent/ReclameAquiRelated';
import SchedulingDropdown from './FilterContent/SchedulingDropdown';
import ShortcutIndicator from './FilterContent/ShortcutIndicator';
// import Tags from './FilterContent/Tags';
import TicketNumber from './FilterContent/TicketNumber';
import TicketStatus from './FilterContent/TicketStatus';
import VIPCustomer from './FilterContent/VIPCustomer';
import Whatsapp from './FilterContent/Whatsapp';
import { FilterWrapper } from './FilterWrapper';
import { makeEndDateDefault, makeStartDateDefault } from './functions';

const FilterContainer = ({
  filterData,
  setFilterData,
  onFilterChange,
  setOpen,
  shortcutFilterData,
  setShortcutFilterData,
  currentShortcutShow,
  setCurrentShortcutShow,
  handleRefreshShortcut
}) => {
  const { t } = useTranslation();
  const classes = inputStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [, setIsLoading] = useState(false);
  const [maskCpfCnpj, setMaskCpfCnpj] = useState('999.999.999-999');
  const [inputValueCreationDate, setInputValueCreationDate] = useState({
    from: '',
    to: ''
  });
  const [dateFrom, setDateFrom] = useState('');
  const [timeFrom, setTimeFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [timeTo, setTimeTo] = useState('');

  const [defaultFilterData] = useState({
    marketPlace: [...Object.values(marketPlacesEnum), 'All']
  });

  const [
    inputValueCreationDateUseEffectParams,
    setInputValueCreationDateUseEffectParams
  ] = useState();

  const [filterForm, setFilterForm] = useState(() => {
    const lsFilter = sessionStorage.getItem('filterData');
    return lsFilter ? JSON.parse(lsFilter) : {};
  });

  useEffect(() => {
    setFilterForm(filterData);
    if (!filterData?.creationDate) {
      setDateFrom('');
      setTimeFrom('');
      setDateTo('');
      setTimeTo('');
    }
  }, [filterData]);

  const submitFilter = () => {
    const mergedFilterData = {
      ...filterData,
      ...filterForm
    };

    Object.entries(mergedFilterData).forEach((value) => {
      const index = value[0];
      if (!mergedFilterData[index]) {
        delete mergedFilterData[index];
      }
    });
    if (inputValueCreationDate.from && inputValueCreationDate.to) {
      mergedFilterData.creationDate = `${moment
        .utc(inputValueCreationDate.from)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:00')}-${moment
        .utc(inputValueCreationDate.to)
        .utcOffset(new Date().getTimezoneOffset())
        .format('DD/MM/YYYY HH:mm:59')}`;
    }

    if (!haveB2BOnFilter(mergedFilterData.marketPlace)) {
      mergedFilterData.reclameAqui = undefined;
    }

    sessionStorage.setItem('useFilterInParams', false); // Ignore filter by query
    if (
      mergedFilterData?.reasonName === 'Outros' ||
      mergedFilterData?.reasonGroup === 'TODOS'
    ) {
      delete mergedFilterData.reasonDesc;
    }
    setFilterData(mergedFilterData);
    onFilterChange(mergedFilterData);
  };

  const clearFilterFormCreationDateInputs = () => {
    const newState = { ...inputValueCreationDate };
    newState.from = '';
    newState.to = '';

    setInputValueCreationDateUseEffectParams('remove');
    setInputValueCreationDate(newState);
    sessionStorage.setItem('useFilterInParams', false);
    localStorage.removeItem('dashFilterData');
  };

  const clearFilterForm = () => {
    setIsLoading(true);

    clearFilterFormCreationDateInputs();

    const defaultStateData = {};

    defaultStateData.ticketType = 'AFTER_SALE';

    sessionStorage.setItem('filterData', JSON.stringify(defaultFilterData));

    setDateFrom('');
    setTimeFrom('');
    setDateTo('');
    setTimeTo('');
    setCurrentShortcutShow({});
    onFilterChange({ ...defaultFilterData, ...defaultStateData });
    setFilterData({ ...defaultFilterData, ...defaultStateData });
  };

  const validadeInputValueCreation = () => {
    if (inputValueCreationDateUseEffectParams === 'remove') {
      setInputValueCreationDateUseEffectParams('');
    }
  };

  const handleCpfCnpjMask = (value) => {
    value = value.replace(/\D/g, '');
    if (value.length > 11) {
      setMaskCpfCnpj('99.999.999/9999-99');
    } else if (value.length <= 11) {
      setMaskCpfCnpj('999.999.999-99');
    }
  };

  useEffect(validadeInputValueCreation, [inputValueCreationDate]);

  // const [tagOptions, setTagOptions] = useState([]);

  // const refreshTagsContent = () => {
  //   API.get('/auth/lambda/ticket-tag').then((response) => {
  //     if (response.data.length > 0) {
  //       const labelList = response?.data?.map((item) => item.tag_name);
  //       setTagOptions(labelList);
  //     }
  //   });
  // };

  // const [loaded, setLoaded] = useState(false);

  // useEffect(() => {
  //   if (!loaded) {
  //     refreshTagsContent();
  //     setLoaded(true);
  //   }
  // }, []);

  const changeDateTime = (toOrFrom, date, time) => {
    if (!time && date) {
      time = 'T00:00';
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setTimeFrom('00:00') : setTimeTo('23:59');
    }
    if (time) {
      time = `T${time}`;
    }
    if (!date && time) {
      date = moment(Date.now()).format('YYYY-MM-DD');
      // eslint-disable-next-line no-unused-expressions
      toOrFrom === 'from' ? setDateFrom(date) : setDateTo(date);
    }
    const newState = { ...inputValueCreationDate };
    // eslint-disable-next-line no-unused-expressions
    toOrFrom === 'from'
      ? (newState.from = `${date}${time}`)
      : (newState.to = `${date}${time}`);
    setInputValueCreationDate(newState);
  };

  const handleRemoveShortcutFlag = () => {
    setShortcutFilterData(false);
    setCurrentShortcutShow({});
  };

  const handleDeleteShortcut = () => {
    // TO DO DELETE Shortcut
    try {
      API.delete(
        `/auth/ms-ticket/v1/protocol-filter/deleteById/${currentShortcutShow.id}`
      ).then(() => {
        handleRefreshShortcut();
        handleRemoveShortcutFlag();
        setFilterForm({});
        setDateFrom('');
        setDateTo('');
        enqueueSnackbar('Atalho excluido com sucesso.', {
          variant: 'success'
        });
      });
    } catch (e) {
      enqueueSnackbar('Erro ao excluir atalho.', {
        variant: 'error'
      });
    }
  };

  useEffect(() => {
    changeDateTime('from', dateFrom, timeFrom);
  }, [dateFrom, timeFrom]);

  useEffect(() => {
    changeDateTime('to', dateTo, timeTo);
  }, [dateTo, timeTo]);

  useEffect(() => {
    if (
      typeof shortcutFilterData === 'object' &&
      Object.keys(shortcutFilterData).length > 0
    ) {
      setFilterForm(shortcutFilterData);
      const newDateFrom = shortcutFilterData?.creationDate
        ?.split('-')[0]
        .split(' ')[0]
        .replaceAll('/', '-');
      const newDateTo = shortcutFilterData?.creationDate
        ?.split('-')[1]
        .split(' ')[0]
        .replaceAll('/', '-');
      setDateFrom(makeStartDateDefault(newDateFrom));
      setDateTo(makeEndDateDefault(newDateTo));
      setShortcutFilterData(false);
    }
  }, [shortcutFilterData]);

  const handleSetFilter = (e) => {
    setFilterForm({
      ...filterForm,
      [e.target.name]: e.target.value
    });
  };

  const reasons = reasonList();
  const handleSetFilterReason = (e) => {
    const reasonName = e.target.value;
    const reasonGroup = reasons.find(({ name }) => name === reasonName)?.group;

    delete filterForm.reasonDesc;
    setFilterForm({
      ...filterForm,
      [e.target.name]: reasonName,
      reasonGroup
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <Grid container>
          <Grid item style={{ backgroundColor: '#f4f6f8' }}>
            <Box
              style={{
                width: '560px',
                padding: '12px 24px 0px',
                border: '0',
                display: 'Flex',
                flexDirection: 'column',
                borderLeft: '1px solid #ccc'
              }}
            >
              <FilterWrapper>
                <ShortcutIndicator
                  {...{ currentShortcutShow, handleDeleteShortcut }}
                />
                <br />
                <FilterAttendants
                  {...{ classes }}
                  filterForm={filterForm}
                  currentShortcutShow={currentShortcutShow}
                  setCurrentShortcutShow={setCurrentShortcutShow}
                  handleRemoveShortcutFlag={handleRemoveShortcutFlag}
                  setFilterForm={setFilterForm}
                />
                {/** Status do protocolo */}
                <TicketStatus
                  {...{
                    filterForm,
                    handleSetFilter,
                    handleRemoveShortcutFlag,
                    filterData
                  }}
                />
                {/** Número do protocolo Conecta */}
                <TicketNumber {...{ filterForm, handleSetFilter }} />
                {/** Protocolo do Marketplace */}
                {filterData?.marketPlace.find((item) => {
                  return (
                    true &&
                    ['VIVO', 'NETSHOES', 'MAGALU', 'CNOVA', 'COLOMBO'].includes(
                      item
                    )
                  );
                }) && (
                  <MarketplaceTicket {...{ filterForm, handleSetFilter }} />
                )}
                {/** Status B2W */}
                {filterData?.marketPlace.find((item) => {
                  return true && item === 'B2W';
                }) && <B2WStatus {...{ filterForm, handleSetFilter }} />}
                {/** Status do Cancelamento */}
                {/* <CancellationStatus {...{ filterForm, handleSetFilter }} /> */}
                {/** Agendamento dropdown */}
                <SchedulingDropdown {...{ filterForm, handleSetFilter }} />
                <FilterReasonGroups
                  filterForm={filterForm}
                  handleSetFilterReason={handleSetFilterReason}
                />
                <FilterSubReason {...{ filterForm, handleSetFilter }} />
                {/** Like e Dislike */}
                {(filterData?.marketPlace?.includes('CUSTOMER_GUIDE') ||
                  filterData?.marketPlace?.includes('CUSTOMER_GUIDE_B2B')) && (
                  <LikeAndDislike
                    filterForm={filterForm}
                    setFilterForm={setFilterForm}
                    handleRemoveShortcutFlag={handleRemoveShortcutFlag}
                  />
                )}

                <Typography
                  gutterBottom
                  variant="h5"
                  style={{ marginTop: '15px' }}
                >
                  Filtrar somente por:
                </Typography>
                {/** Whatsapp */}
                {filterData?.marketPlace.find((item) => {
                  return true && item === 'CUSTOMER_GUIDE';
                }) && (
                  <Whatsapp
                    {...{ filterForm, setFilterForm, handleRemoveShortcutFlag }}
                  />
                )}
                {/* RECLAME AQUI */}
                {haveB2BOnFilter(filterData.marketPlace) && (
                  <ReclameAquiRelated {...{ filterForm, setFilterForm }} />
                )}
                {/** Reclamação Mercado Livre */}
                {filterData?.marketPlace.find((item) => {
                  return true && item === 'MERCADO_LIVRE';
                }) && <MLClaim {...{ filterForm, setFilterForm }} />}
                {/** Processo Jurídico */}
                {filterData?.marketPlace.find((item) => {
                  return true && item === 'B2W';
                }) && <B2WLegalProcess {...{ filterForm, setFilterForm }} />}
                {/** SLA */}
                <SLA {...{ filterForm, setFilterForm }} />
                {/** Agendamento */}
                {/* <Scheduling {...{ filterForm, setFilterForm }} /> */}
                {/** Reclamação Magalu */}
                {filterData?.marketPlace.find((item) => {
                  return true && item === 'MAGALU';
                }) && <MagaluClaim {...{ filterForm, setFilterForm }} />}
                {/** Mediados */}
                {filterData?.marketPlace.find((item) => {
                  return true && ['B2W', 'MERCADO_LIVRE'].includes(item);
                }) && (
                  <Mediated
                    {...{ filterForm, setFilterForm, handleRemoveShortcutFlag }}
                  />
                )}
                {/** Incidentes */}
                {filterData?.marketPlace.find((item) => {
                  return true && ['KABUM', 'CARREFOUR'].includes(item);
                }) && (
                  <Incidents
                    {...{ filterForm, setFilterForm, handleRemoveShortcutFlag }}
                  />
                )}
                {/** Cliente destaque */}
                <VIPCustomer {...{ filterForm, setFilterForm }} />
                {/** Data de abertura */}
                <OpeningDate
                  {...{
                    dateFrom,
                    setDateFrom,
                    dateTo,
                    setDateTo,
                    handleRemoveShortcutFlag
                  }}
                />
                {/** Pedecom */}
                <Pedecom {...{ filterForm, handleSetFilter }} />
                {/** Customer ID */}
                <Document
                  {...{
                    filterForm,
                    setFilterForm,
                    handleRemoveShortcutFlag,
                    maskCpfCnpj,
                    handleCpfCnpjMask,
                    setMaskCpfCnpj
                  }}
                />
                {/* <br /> */}
                {/** Tags */}
                {/* <Tags {...{ filterForm, tagOptions, handleSetFilter }} /> */}
              </FilterWrapper>
              <br />
            </Box>
          </Grid>
        </Grid>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            background: '#fff',
            padding: '15px',
            marginRight: '20px'
          }}
        >
          <Button
            onClick={() => {
              clearFilterForm();
            }}
            variant="contained"
          >
            {t('i18n.ticketslistview.CLEAR')}
          </Button>
          <Button
            onClick={() => {
              submitFilter(true);
              setOpen(false);
            }}
            variant="contained"
            color="primary"
          >
            {t('i18n.ticketslistview.SEARCH')}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default FilterContainer;
