export const filterFormData = {
  serviceAttendantLogin: {
    value: '',
    label: 'i18n.ticketslistview.ATTENDANT'
  },
  id: { value: '', label: 'ID' },
  customerCpf: { value: '', label: 'i18n.ticketslistview.DOCUMENT' },
  orderId: { value: '', label: 'i18n.ticketslistview.ORDER_ID_PEDECOM' },
  ticketType: { value: '', label: 'i18n.ticketslistview.TICKET_TYPE' },
  creationDate: { value: '', label: 'i18n.ticketslistview.OPENING_DATE' },
  tags: { value: '', label: 'Tags' },
  vipClient: { value: '', label: 'i18n.ticketslistview.VIPCLIENT' },
  reclamacaoMercadoLivre: {
    value: '',
    label: 'i18n.ticketslistview.ML_CLAIM'
  },
  ticketStatus: { value: '', label: 'i18n.ticketslistview.STATUS' },
  protocoloMarketplace: {
    value: '',
    label: 'i18n.ticketslistview.ticketStatus.protocoloMarketplace'
  },
  filtersB2W: {
    value: '',
    label: 'i18n.ticketslistview.B2W_STATUS'
  },
  cancellationStatus: { value: '', label: 'Status do Cancelamento' },
  sla: { value: '', label: 'i18n.ticketslistview.SLA' },
  reclameAqui: { value: '', label: 'i18n.marketplaces.RECLAME_AQUI' },
  mediatedOpened: {
    value: '',
    label: 'i18n.ticketslistview.MEDIATED_OPENED'
  },
  mediatedClosed: {
    value: '',
    label: 'i18n.ticketslistview.MEDIATED_CLOSED'
  },
  incidentOpened: {
    value: '',
    label: 'i18n.ticketslistview.INCIDENT_OPENED'
  },
  incidentClosed: {
    value: '',
    label: 'i18n.ticketslistview.INCIDENT_CLOSED'
  },
  marketPlaceJuridicalIdList: {
    value: '',
    label: 'i18n.legalprotocol.LEGAL_PROCESS'
  },
  lastStepName: {
    value: '',
    label: 'i18n.ticketslistview.CANCELATION_STATUS'
  },
  withReason: {
    value: '',
    label: 'i18n.ticketslistview.WITH_REASON'
  },
  magaluClaim: {
    value: '',
    label: 'Reclamação Magalu'
  },
  protocolSchedulingStatus: {
    value: '',
    label: 'i18n.ticketslistview.SCHEDULING'
  },
  satisfactionScore: {
    value: '',
    label: 'i18n.simplewords.RATING'
  },
  commentOnTheScore: {
    value: '',
    label: 'i18n.simplewords.COMMENTS'
  },
  reasonName: {
    value: '',
    label: 'i18n.ticketcontainer.REASON_LABEL'
  },
  reasonDesc: {
    value: '',
    label: 'i18n.ticketcontainer.REASON_DETAIL_LABEL'
  }
};
