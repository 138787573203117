export default [
  'NEW',
  'AWAITING',
  'ANSWERED',
  'AWAITINGCLOSED',
  'AWAITINGPARTNER',
  'SUSPENDED',
  'RESOLVED',
  'CLOSED',
  'ALL_OPENED'
];
