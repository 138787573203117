import React, { useState } from 'react';
import { Button, SwipeableDrawer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components';
import { COLORS } from '../../styles/settings/colors.config';
import ModaslComponent from './Tools/ModalsComponent';
import SwitchLanguage from './switchLanguage';
import LogoutButton from './logoutButton';
import { FavoriteButton } from './favoritesBar/favoriteButton';

import { MenuHooks } from './menuHooks';
import FavoriteSwitch from './favoritesBar/favoriteSwitch';

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'transparent',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    color: COLORS.greenText,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(MuiAccordion);

const MenuTitle = styled(Typography)`
  padding: 15px;
  color: ${COLORS.grayText};
  font-size: 16px;
  font-weight: bold;
  // border-bottom: 1px solid #ccc;
`;

const CustomTypography = styled.span`
  font-family: Roboto;
  font-size: 16px;
`;

const CustomAction = styled(Link)`
  color: ${COLORS.greenText};
  display: flex;
  padding: 14px 16px;
  cursor: pointer;
  padding-left: ${(props) => props.subitem === 'true' && '24px !important'};
  // border-top: 1px solid ${COLORS.borderGray};
  :hover {
    text-decoration: underline;
  }
`;

const CustomLabel = styled(Typography)`
  color: ${COLORS.grayText};
  padding: 14px 16px;
  padding-left: ${(props) => props.subitem === 'true' && '24px !important'};
  border-top: 1px solid ${COLORS.borderGray};
  cursor: inherit;
  :hover {
    text-decoration: none;
  }
`;

const CustomAccordionSummary = styled(AccordionSummary)`
  color: ${COLORS.greenText};
  svg {
    fill: ${COLORS.greenText};
    font-size: 2.5rem;
  }
  :hover {
    text-decoration: underline;
  }
`;

const CustomAccordionDetails = styled(MuiAccordionDetails)`
  padding: 0px 12px 0px 16px;
  display: block;
`;

const CustomIconButton = styled(Button)`
  color: #fff;
  margin-left: 12px;
`;

const CustomDrawer = styled('div')`
  div {
    display: none !important;
  }
`;

const CustomFavoriteLink = styled('div')`
  display: flex;
  align-items: center;
  gap: 8px;
  border-top: 1px solid ${COLORS.borderGray};
  :first-child {
    border-top: 0px solid ${COLORS.borderGray};
  }
  // padding-left: 16px;
  padding: ${(props) => (props.padding ? '0px' : '0px 12px 0px 0px')};
  width: 100%;
  justify-content: space-between;
  // .hover {
  //   opacity: 0;
  // }
  // :hover {
  //   .hover {
  //     opacity: 1;
  //   }
  // }
`;

const CustomFavoriteAction = styled(Link)`
  color: ${COLORS.greenText};
  display: flex;
  padding: 14px 16px;
  cursor: pointer;
  padding-left: ${(props) => props.subitem === 'true' && '24px !important'};
  :hover {
    text-decoration: underline;
  }
`;

const GenerateLinks = ({ item, actions, anchor, toggleDrawer, padding }) => {
  const isChatView = window.location.pathname === '/chats';
  const showFavoriteButton = useSelector((state) => state.editFavoriteReducer);

  const handleGetAction = (act) => {
    switch (act) {
      case 'setOpenSearchLegalProcess':
        actions[0](true);
        break;
      case 'setOpenInvoice':
        actions[1](true);
        break;
      case 'setOpenOrders':
        actions[2](true);
        break;
      case 'setOpenTracking':
        actions[3](true);
        break;
      case 'setOpenMarketplaceSupportModal':
        actions[4](true);
        break;
      default:
        break;
    }
  };

  const handleMakeFavoriteDetail = (detail, type) => {
    if (detail.edit) {
      detail.name = detail.edit.favoriteDetails.name;
      detail.userName = detail.edit.favoriteDetails.userName;
      detail.id = detail.edit.favoriteDetails.id;
      detail.type = type;
      detail.fields = [{ type: detail.edit.type, value: detail.edit.value }];
    }
    return detail;
  };

  if (item.show) {
    if (item.action) {
      return (
        <CustomFavoriteLink padding={padding}>
          <CustomAction
            onClick={() => handleGetAction(item.action)}
            subitem={item.subItem}
            to="#"
          >
            <CustomTypography>{item.text}</CustomTypography>
          </CustomAction>
          {showFavoriteButton && (
            <FavoriteButton
              className="hover"
              {...{
                details: handleMakeFavoriteDetail(item, 'link'),
                type: 'link',
                hide: 'true'
              }}
            />
          )}
        </CustomFavoriteLink>
      );
    }

    if (item.path) {
      return (
        <CustomFavoriteLink padding={padding}>
          <CustomFavoriteAction
            subitem={item?.subItem?.toString()}
            to={item.path}
            target={isChatView && item.openNewTab ? '_blank' : ''}
            onClick={() => {
              if (!item.openNewTab || (item.openNewTab && !isChatView)) {
                toggleDrawer(anchor, false)();
              }
            }}
          >
            {item?.text2 ? (
              <>
                <CustomTypography>
                  <b>{item.text}</b>
                </CustomTypography>
                <CustomTypography>{item.text2}</CustomTypography>
              </>
            ) : (
              <CustomTypography>{item.text}</CustomTypography>
            )}
          </CustomFavoriteAction>
          {showFavoriteButton && (
            <FavoriteButton
              className="hover"
              {...{
                details: handleMakeFavoriteDetail(item, 'link'),
                type: 'link'
              }}
            />
          )}
        </CustomFavoriteLink>
      );
    }
    return (
      <CustomLabel>
        <CustomTypography>{item.text}</CustomTypography>
      </CustomLabel>
    );
  }
  return <></>;
};

const CreateAccordion = ({
  menu,
  expanded,
  handleChange,
  anchor,
  toggleDrawer,
  actions
}) => {
  return (
    <Accordion
      square
      expanded={expanded === menu.text}
      onChange={handleChange(menu.text)}
    >
      <CustomAccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        {menu?.text2 ? (
          <>
            <CustomTypography>
              <b>{menu.text}</b>
            </CustomTypography>
            <CustomTypography>{menu.text2}</CustomTypography>
          </>
        ) : (
          <CustomTypography>{menu.text}</CustomTypography>
        )}
      </CustomAccordionSummary>
      <CustomAccordionDetails>
        {menu?.items?.map((item) => (
          <GenerateLinks
            key={Math.random()}
            {...{ item, actions, anchor, toggleDrawer, padding: true }}
          />
        ))}
      </CustomAccordionDetails>
    </Accordion>
  );
};

const Menu = ({
  anchor,
  classes,
  toggleDrawer,
  setOpenOrders,
  setOpenInvoice,
  setOpenTracking,
  setOpenSearchLegalProcess,
  setOpenMarketplaceSupportModal,
  getHealthMonitor,
  userAlive
}) => {
  const { menuItems } = MenuHooks();
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom'
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
      style={{ top: '0px !important' }}
    >
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}
      >
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '8px'
            }}
          >
            <MenuTitle>Menu Conecta</MenuTitle>
            <FavoriteSwitch />
          </div>

          {menuItems.map((item) => {
            if (item.show && item.items) {
              return (
                <CreateAccordion
                  key={item.text}
                  {...{
                    menu: item,
                    expanded,
                    handleChange,
                    anchor,
                    toggleDrawer,
                    actions: [
                      setOpenSearchLegalProcess,
                      setOpenInvoice,
                      setOpenOrders,
                      setOpenTracking,
                      setOpenMarketplaceSupportModal
                    ]
                  }}
                />
              );
            }
            if (item.show && !item.items) {
              return (
                <GenerateLinks
                  key={item.text}
                  {...{ item, anchor, toggleDrawer }}
                />
              );
            }
            return <></>;
          })}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <SwitchLanguage />
          <LogoutButton {...{ getHealthMonitor, userAlive }} />
        </div>
      </div>
    </div>
  );
};

const DrawerMenu = ({
  toggleDrawer,
  state,
  classes,
  getHealthMonitor,
  userAlive
}) => {
  const [openOrders, setOpenOrders] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const [openTracking, setOpenTracking] = useState(false);
  const [openSearchLegalProcess, setOpenSearchLegalProcess] = useState(false);
  const [
    openMarketplaceSupportModal,
    setOpenMarketplaceSupportModal
  ] = useState(false);

  return (
    <div>
      {['right'].map((anchor) => (
        <React.Fragment key={Math.random()}>
          <CustomIconButton
            onClick={toggleDrawer(anchor, true)}
            aria-label="delete"
            endIcon={<MenuIcon />}
          >
            MENU
          </CustomIconButton>

          <CustomDrawer>
            <SwipeableDrawer
              disableEnforceFocus
              ModalProps={{ disableAutoFocus: true, disableEnforceFocus: true }}
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
              style={{ top: '0px !important' }}
              classes={{
                paper: classes.paper
              }}
            >
              <Menu
                {...{
                  anchor,
                  classes,
                  toggleDrawer,
                  setOpenSearchLegalProcess,
                  setOpenInvoice,
                  setOpenOrders,
                  setOpenTracking,
                  setOpenMarketplaceSupportModal,
                  getHealthMonitor,
                  userAlive
                }}
              />
            </SwipeableDrawer>
          </CustomDrawer>
        </React.Fragment>
      ))}

      <ModaslComponent
        {...{
          openOrders,
          setOpenOrders,
          openInvoice,
          setOpenInvoice,
          openTracking,
          setOpenTracking,
          openSearchLegalProcess,
          setOpenSearchLegalProcess,
          openMarketplaceSupportModal,
          setOpenMarketplaceSupportModal
        }}
      />
    </div>
  );
};

export { DrawerMenu };
