import React from 'react';

const Logo = props => {
  return (
    <img
      alt="Logo"
      src="/static/logoAcerConecta082021.png"
      style={{
        maxHeight: 63.5,
        marginLeft: -23,
        marginTop: 0,
        marginBottom: 0
      }}
      {...props}
    />
  );
};

export default Logo;
