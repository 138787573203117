/* eslint-disable react/no-array-index-key */
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AcerModal from '../../components/AcerModal';
import API from '../../services/api';
import ChangeDataType from './actionTypes/ChangeDataType';
import SendEmail from './actionTypes/SendEmail';

const ActionModal = ({ dataState, openState, closeParent }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const defaultProperties = {};

  const [open, setOpen] = useState(openState);
  const [actionData, setActionData] = useState('');
  const [mailBodyContentHolder, setMailBodyContentHolder] = useState(null);

  const handleClose = () => {
    closeParent();
    setOpen(false);
  };

  const setActionDataState = (key, value) => {
    const newState = { ...actionData };
    newState[key] = value;
    setActionData(newState);
  };

  const submitNewAction = () => {
    if (!actionData.description.trim()) {
      enqueueSnackbar(t('i18n.newAutomation.NAME_REQUIRED'), {
        variant: 'error'
      });
      return;
    }

    let request = API.post;
    const editing = actionData?.id;
    if (editing) {
      request = API.put;
    }
    if (mailBodyContentHolder) {
      actionData.properties.emailBody = mailBodyContentHolder;
    }

    request(`/auth/ms-ticket/v1/automation-actions`, actionData)
      .then(() => {
        enqueueSnackbar(t('i18n.newAutomation.ACTION_CREATED_SUCCESS'), {
          variant: 'success'
        });
        handleClose();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.newAutomation.ACTION_CREATED_ERR'), {
          variant: 'error'
        });
        handleClose();
      });
  };

  useEffect(() => {
    setOpen(openState);
  }, [openState]);

  useEffect(() => {
    setActionData(
      dataState || {
        automationActionType: 'CHANGE_TYCKET_DATA',
        properties: defaultProperties,
        description: ''
      }
    );
  }, [dataState]);

  return (
    <AcerModal
      hasAction
      maxWidth="md"
      fullWidth
      open={open}
      onClose={handleClose}
      modalTitle={
        actionData?.id
          ? t('i18n.newAutomation.EDIT_ACTION')
          : t('i18n.newAutomation.NEW_ACTION')
      }
      cancelActionLabel={t('i18n.simplewords.CANCEL')}
      confirmActionFunc={submitNewAction}
      confirmActionLabel={t('i18n.simplewords.FINISH')}
    >
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={8}>
              <TextField
                size="small"
                value={actionData.description || ''}
                onChange={ev => {
                  setActionDataState('description', ev.target.value);
                }}
                fullWidth
                label={t('i18n.newAutomation.ACTION_NAME')}
                variant="outlined"
              />
            </Grid>
            <Grid item lg={4}>
              <FormControl size="small" fullWidth variant="outlined">
                <InputLabel>
                  {t('i18n.newAutomation.TYPE_OF_ACTION')}
                </InputLabel>
                <Select
                  size="small"
                  value={actionData.automationActionType}
                  label={t('i18n.newAutomation.TYPE_OF_ACTION')}
                  onChange={ev => {
                    const newState = { ...actionData };
                    newState.automationActionType = ev.target.value;
                    newState.properties = defaultProperties;
                    setActionData(newState);
                  }}
                >
                  <MenuItem value="CHANGE_TYCKET_DATA">
                    {t('i18n.newAutomation.CHANGE_TICKET_DATA')}
                  </MenuItem>
                  <MenuItem value="SEND_EMAIL">
                    {t('i18n.newAutomation.SEND_EMAIL')}
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        {actionData.automationActionType === 'CHANGE_TYCKET_DATA' && (
          <ChangeDataType
            properties={actionData.properties}
            setProperties={value => {
              setActionDataState('properties', value);
            }}
          />
        )}
        {actionData.automationActionType === 'SEND_EMAIL' && (
          <SendEmail
            setMailBodyContentHolder={value => {
              setMailBodyContentHolder(value);
            }}
            properties={actionData.properties}
            setProperties={value => {
              setActionDataState('properties', value);
            }}
          />
        )}
      </Card>
    </AcerModal>
  );
};

export default ActionModal;
