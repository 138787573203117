import { Box, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SelectComponent from '../../../../components/Select.component';
import statusEnum from '../../../../enums/ticketsStatus.enum';

const getRemainingMarketplacesCallback = marketplace => {
  return (
    marketplace !== 'CNOVA' &&
    marketplace !== 'COLOMBO' &&
    marketplace !== 'MAGALU'
  );
};

const TicketStatus = ({ filterForm, handleSetFilter, filterData }) => {
  const { t } = useTranslation();

  const shouldRemoveSuspendedStatus = useMemo(() => {
    const remainingMarketplaces = filterData.marketPlace.filter(
      getRemainingMarketplacesCallback
    );

    return (
      remainingMarketplaces.length === 0 && filterData.marketPlace.length > 0
    );
  }, [filterData]);

  const filteredStatusEnum = useMemo(() => {
    if (shouldRemoveSuspendedStatus) {
      return statusEnum.filter(s => s !== 'SUSPENDED');
    }

    return statusEnum;
  }, [shouldRemoveSuspendedStatus]);

  return (
    <Box mb={2}>
      <Grid container>
        <Grid item xs={7}>
          <SelectComponent
            {...{
              form: filterForm,
              setForm: handleSetFilter,
              label: t('i18n.ticketslistview.STATUS'),
              name: 'ticketStatus'
            }}
            options={filteredStatusEnum.map(item => {
              if (item === 'ALL_OPENED') {
                return {
                  name: 'Todos abertos',
                  value: 'NEW,AWAITING,ANSWERED,AWAITINGPARTNER,SUSPENDED'
                };
              }
              return {
                name: t(`i18n.ticketslistview.ticketStatus.${item}`),
                value: item
              };
            })}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default TicketStatus;
