/* eslint-disable react/destructuring-assignment */
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  Typography,
  Grid
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link } from 'react-router-dom';
import userService from '../../services/user.service';
import config from '../../config';
import LanguageButton from '../../layouts/DashboardLayout/Tools/LanguageButton';
import Page from '../../components/Page';
import API from '../../services/api';
import authService from '../../auth/auth.service';
import formHelper from '../../utils/formHelper';
import PasswordValidationSection from './PasswordValidationSection';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(25)
  },
  gridLeft: {
    textAlign: 'right'
  }
}));

const ChangePasswordView = props => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [formData, setFormdata] = useState(
    formHelper.simpleData2FormData({
      newPassword: {
        required: true
      }
    })
  );
  const history = useHistory();

  useEffect(() => {
    setUserName(props?.location?.state?.state?.username || '');
    setPassword(props?.location?.state?.state?.password || '');
  }, []);

  const handleLogin = async () => {
    try {
      const { data } = await API.post(`${config.cognito.authUrl}/login`, {
        username,
        password: formData.newPassword.value
      });
      authService.setAccessTokens(data);
      history.push(userService.getFirstAvailableRoute());
    } catch (error) {
      enqueueSnackbar(t('i18n.loginview.LOGIN_ERROR'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  const handleSuccess = data => {
    authService.setAccessTokens(data);
    sessionStorage.removeItem('session');
    // history.push('/login');
    handleLogin();
  };

  const passwordIsOK = () => {
    const re = new RegExp(
      '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-+]).{8,}$'
    );
    if (!re.test(formData.newPassword.value)) {
      enqueueSnackbar(t('i18n.validation.INVALIDPASSWORD'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'newPassword');
      return false;
    }
    if (formData.newPassword.value !== confirmNewPassword) {
      enqueueSnackbar(t('i18n.loginview.NEWPWD_DIFF_CONFIRMPWD'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      formHelper.setFormError(formData, setFormdata, 'newPassword');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!passwordIsOK()) {
      return;
    }
    const session = sessionStorage.getItem('session');

    try {
      const { data } = await API.post(`${config.cognito.authUrl}/first-login`, {
        username,
        newPassword: formData.newPassword.value,
        session
      });
      handleSuccess(data);
    } catch (error) {
      enqueueSnackbar(t('i18n.loginview.LOGIN_ERROR'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Box mb={3}>
            <Card>
              <CardContent>
                <Box display="flex" flexDirection="column">
                  <Box display="flex" flexDirection="row">
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      spacing={10}
                    >
                      <Grid item md={8}>
                        <Typography color="textPrimary" variant="h2">
                          {t('i18n.loginview.NEW_PASSWORD')}
                        </Typography>
                      </Grid>
                      <Grid item md={4} className={classes.gridLeft}>
                        <LanguageButton />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box paddingTop={2}>
                    <Typography variant="subtitle1">
                      {t('i18n.loginview.CHANGE_TEMPORARY_PASSWORD')}
                    </Typography>
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.LOGIN')}
                      variant="outlined"
                      fullWidth
                      value={username}
                      disabled={!!username}
                      id="name-usuario"
                      onChange={ev => {
                        setUserName(ev.target.value);
                      }}
                    />
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.OLD_PASSWORD')}
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={password}
                      disabled={password}
                      id="password-user"
                      onChange={ev => {
                        setPassword(ev.target.value);
                      }}
                    />
                  </Box>
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.NEW_PASSWORD')}
                      type="password"
                      // helperText={t('i18n.loginview.PASSWORD_RULES')}
                      variant="outlined"
                      fullWidth
                      onChange={htmlElemtEvent => {
                        formHelper.setFormvalue(
                          formData,
                          setFormdata,
                          'newPassword',
                          htmlElemtEvent.target.value
                        );
                        setNewPassword(htmlElemtEvent.target.value);
                      }}
                    />
                  </Box>
                  <PasswordValidationSection password={newPassword} />
                  <Box paddingTop={2}>
                    <TextField
                      label={t('i18n.loginview.CONFIRM_NEW_PASSWORD')}
                      type="password"
                      variant="outlined"
                      fullWidth
                      value={confirmNewPassword}
                      onChange={ev => {
                        setConfirmNewPassword(ev.target.value);
                      }}
                    />
                  </Box>
                  <Grid container justifyContent="space-between">
                    <Grid item lg>
                      <Box paddingTop={2}>
                        <Button
                          variant="contained"
                          color="default"
                          component={Link}
                          to="/"
                        >
                          {t('i18n.simplewords.CANCEL')}
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item lg className={classes.gridLeft}>
                      <Box paddingTop={2}>
                        <Button
                          disabled={
                            !formData.newPassword.value || !confirmNewPassword
                          }
                          variant="contained"
                          color="primary"
                          onClick={handleSubmit}
                        >
                          {t('i18n.loginview.CHANGE_PASSWORD')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default ChangePasswordView;
