import React, { useContext } from 'react';
import { Box, Grid, Typography, Breadcrumbs } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { ChatsListContext } from './context';
import { CustomTitle, FilterChip } from './datatable.custom.components';

import { searchB2bOnCurrentUrl } from '../../../../utils/b2bHelper';

import {
  OptionsConfig,
  ColumnsConfig,
  FilterFormData
} from './datatable.config';

import {
  CustomDataTableWrapper,
  CustomDataTable
} from '../../../../styles/components/CustomDataTable';

const DataTableComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    loading,
    data,
    refreshTableContent,
    filter,
    setFilter,
    currentPage,
    totalPages,
    totalElements,
    size
  } = useContext(ChatsListContext);

  const columns = ColumnsConfig({ t, data });
  const options = OptionsConfig({
    t,
    loading,
    data,
    refreshTableContent,
    currentPage,
    totalPages,
    totalElements,
    size,
    history
  });

  const removeFilterFromChip = key => {
    const newFilterData = JSON.parse(JSON.stringify(filter));

    if (key === 'ruleOperator') {
      delete newFilterData.ruleOperator;
      delete newFilterData.firstResponseTimeInSeconds;
      delete newFilterData.responseTimeRelationalOperator;
    } else {
      delete newFilterData[key];
    }

    // const newFilterData = JSON.parse(JSON.stringify(filter));
    // delete newFilterData[key];
    setFilter(newFilterData);
    sessionStorage.setItem('filterHistory', JSON.stringify(newFilterData));
    // setRemovedChip(key);
  };

  return (
    <CustomDataTableWrapper style={{ opacity: loading ? 0.3 : 1 }}>
      <CustomDataTable
        title={
          <Grid container direction="column" spacing={0}>
            <Grid item>
              <Breadcrumbs gutterBottom aria-label="breadcrumb">
                <Link
                  color="inherit"
                  style={{ textDecoration: 'none' }}
                  to={`../chats/${searchB2bOnCurrentUrl() ? 'b2b' : ''}`}
                >
                  <CustomTitle variant="h2">Chats</CustomTitle>
                </Link>
                <Typography variant="h2">
                  {`${t('i18n.chatview.CHAT_HISTORY')} ${
                    searchB2bOnCurrentUrl() ? 'B2B' : 'B2C'
                  }`}
                  <Typography variant="caption">
                    {' '}
                    ({t('i18n.simplephrases.CLICK_DETAIL')})
                  </Typography>
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item>
              <Box display="flex" mb={2} style={{ gap: '15px' }}>
                {Object.entries(filter).map(
                  ([key], index) =>
                    key !== 'status' && (
                      <FilterChip
                        {...{ index }}
                        itemKey={key}
                        key={Math.random()}
                        filterData={filter}
                        removeFilterFromChip={removeFilterFromChip}
                        filterFormData={FilterFormData}
                      />
                    )
                )}
              </Box>
            </Grid>
          </Grid>
        }
        data={data}
        columns={columns}
        options={options}
        selectableRowsOnClick
        className="table-clickable"
      />
    </CustomDataTableWrapper>
  );
};

export default DataTableComponent;
