import React from 'react';
import { useTranslation } from 'react-i18next';
import { COLORS } from '../../../../styles/settings/colors.config';
import ChatProtocolCardDataSideBySide from '../../components/ChatProtocolCardDataSideBySide.component';

const CurrentOpenedProtocolsCard = ({
  isRealTime,
  isAdmin,
  handleIsRealTime,
  dataEquipDash,
  dataIndividualDash
}) => {
  const { t } = useTranslation();
  return (
    <ChatProtocolCardDataSideBySide
      isRealTime={isRealTime}
      cardType="openedProtocols"
      firstLabel={t('i18n.dashboard.components.individual')}
      secondaryLabel={t('i18n.dashboard.components.equip')}
      isTeamView={isAdmin}
      headerData
      showMoreDetails
      firstData={handleIsRealTime(
        dataIndividualDash?.abertos,
        dataIndividualDash?.abertos
      )}
      secondaryData={handleIsRealTime(
        dataEquipDash?.abertos_geral,
        dataEquipDash?.abertos_geral
      )}
      descriptionHint={
        isRealTime
          ? t('i18n.dashboard.components.hint.real_time')
          : t('i18n.dashboard.components.hint.open_in_period')
      }
      // downloadProcotols={() => {
      //   downloadProcotols('download-opened-protocol-bi');
      // }}
      extraData={[
        {
          label: t('i18n.ticketcontainer.NEW'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.novos_geral
            : dataIndividualDash?.novos,
          percent: isAdmin
            ? dataEquipDash?.novos_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.novos / dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITING'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_atendimento_geral
            : dataIndividualDash?.aguardando_atendimento,
          percent: isAdmin
            ? dataEquipDash?.aguardando_atendimento_geral /
              dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_atendimento /
              dataIndividualDash?.abertos,
          goal: 1.5,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.ANSWERED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_cliente_geral
            : dataIndividualDash?.aguardando_cliente,
          percent: isAdmin
            ? dataEquipDash?.aguardando_cliente_geral /
              dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_cliente /
              dataIndividualDash?.abertos,
          goal: 10,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.AWAITINGCLOSED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_fechamento_geral || 0
            : dataIndividualDash?.aguardando_fechamento || 0,
          percent: isAdmin
            ? dataEquipDash?.aguardando_fechamento_geral /
              dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_fechamento /
              dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.SUSPENDED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.suspensos_geral
            : dataIndividualDash?.suspensos,
          percent: isAdmin
            ? dataEquipDash?.suspensos_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.suspensos / dataIndividualDash?.abertos,
          divider: false,
          show: true
        },
        {
          label: t('i18n.ticketcontainer.RESOLVED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.resolvidos_geral
            : dataIndividualDash?.resolvidos,
          percent: isAdmin
            ? dataEquipDash?.resolvidos_geral / dataEquipDash?.abertos_geral
            : dataIndividualDash?.resolvidos / dataIndividualDash?.abertos,
          divider: false,
          show: !isRealTime
        },
        {
          label: t('i18n.ticketcontainer.CLOSED'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.fechados_geral_ref_open
            : dataIndividualDash?.fechados_ref_open,
          percent: isAdmin
            ? dataEquipDash?.fechados_geral_ref_open /
              dataEquipDash?.abertos_geral
            : dataIndividualDash?.fechados_ref_open /
              dataIndividualDash?.abertos,
          divider: false,
          show: !isRealTime
        },
        {
          label: t('i18n.ticketslistview.ticketStatus.AWAITINGPARTNER'),
          color: COLORS.grayText,
          value: isAdmin
            ? dataEquipDash?.aguardando_marketplace_geral
            : dataIndividualDash?.aguardando_marketplace,
          percent: isAdmin
            ? dataEquipDash?.aguardando_marketplace_geral /
              dataEquipDash?.abertos_geral
            : dataIndividualDash?.aguardando_marketplace /
              dataIndividualDash?.abertos,
          divider: false,
          show: true
        }
      ]}
    />
  );
};

export default CurrentOpenedProtocolsCard;
