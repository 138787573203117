const reasonList = () => {
  return [
    {
      group: 'N1',
      name: 'Cadastro',
      detail: [
        {
          name: 'Alteração cadastral'
        },
        {
          name: 'Dados de Nota Fiscal'
        },
        {
          name: 'Dados do cliente'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Cashback',
      detail: [
        {
          name: 'Liberação de valores'
        },
        {
          name: 'Prazo de liberação'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Compras (Pré-Venda)',
      detail: [
        {
          name: 'Cotação'
        },
        {
          name: 'Disponibilidades'
        },
        { name: 'Troca Inteligente' }
      ]
    },
    {
      group: 'N1',
      name: 'Entrega',
      detail: [
        {
          name: 'Abertura de PI'
        },
        {
          name: 'Atraso na entrega'
        },
        {
          name: 'Comprovante de entrega'
        },
        {
          name: 'Pagamento de GNRE'
        },
        {
          name: 'Prazo de entrega'
        },
        {
          name: 'Rastreio'
        },
        {
          name: 'Reembolso de frete'
        },
        {
          name: 'Reembolso de taxa GNRE'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Garantia',
      detail: [
        {
          name: 'Adesão de garantia estendida'
        },
        {
          name: 'Cancelamento de garantia estendida'
        },
        {
          name: 'Garantia Acer certificado'
        },
        {
          name: 'Garantia Acer validade'
        },
        {
          name: 'Nota Fiscal de garantia estendida'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Nota Fiscal',
      detail: [
        {
          name: 'Carta de correção'
        },
        {
          name: 'Divergência de valor'
        },
        {
          name: 'XML ou PDF'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Pagamentos',
      detail: [
        {
          name: 'Pagamento de PIX'
        },
        {
          name: 'Pagamento aprovado (Pedido)'
        },
        {
          name: 'Prazos de pagamento'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Pedidos',
      detail: [
        {
          name: 'Acer Club'
        },
        {
          name: 'Bloqueio de Estoque'
        },
        {
          name: 'Cancelamento Marketplace'
        },
        {
          name: 'Cancelamento Mercado Pago'
        },
        {
          name: 'Confirmação de Pedido'
        },
        { name: 'Erro de integração' },
        {
          name: 'Pedido pendente'
        },
        {
          name: 'Solicitação de brindes'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Suporte',
      detail: [
        {
          name: 'Solicitação de peças'
        },
        {
          name: 'Solicitação de reparo'
        },
        {
          name: 'Suporte técnico'
        },
        {
          name: 'Upgrade'
        }
      ]
    },
    {
      group: 'N1',
      name: 'Outros',
      detail: [
        {
          name: 'Texto livre'
        }
      ]
    },
    {
      group: 'N2',
      name: 'Cancelamento',
      detail: [
        {
          name: 'Arrependimento',
          requireComment: true
        },
        {
          name: 'Avaria no transporte',
          requireComment: true
        },
        {
          name: 'Bloqueio de Estoque'
        },
        {
          name: 'Cancelamento indevido/estorno pelo Marketplace'
        },
        {
          name: 'Endereço incorreto'
        },
        { name: 'Erro de integração' },
        {
          name: 'Extravio'
        },
        {
          name: 'Extravio Mercado Envios'
        },
        {
          name: 'Fraude'
        },
        {
          name: 'Indisponibilidade de item'
        },
        {
          name: 'Insucesso na entrega'
        },
        {
          name: 'Vício de fábrica',
          requireComment: true
        }
      ]
    },
    {
      group: 'N2',
      name: 'Devolução',
      detail: [
        {
          name: 'Devolução agilizada Mercado Livre'
        },
        {
          name: 'Devolução por duplicidade'
        },
        {
          name: 'Modelo incorreto'
        }
      ]
    },
    {
      group: 'N2',
      name: 'Reenvio',
      detail: [
        {
          name: 'Novo pedido (extravio)'
        },
        {
          name: 'Novo pedido (insucesso na entrega)'
        }
      ]
    },
    {
      group: 'N2',
      name: 'Troca',
      detail: [
        {
          name: 'Avaria',
          requireComment: true
        },
        {
          name: 'Vício após recebimento (reincidência)',
          requireComment: true
        },
        {
          name: 'Vício até 7 dias',
          requireComment: true
        }
      ]
    },
    {
      group: 'N2',
      name: 'Outros',
      detail: [
        {
          name: 'Texto livre'
        }
      ]
    },
    {
      group: 'TODOS',
      name: 'N1',
      detail: []
    },
    {
      group: 'TODOS',
      name: 'N2',
      detail: []
    },
    {
      group: 'TODOS',
      name: 'Sem motivos',
      detail: []
    }
  ];
};

export default reasonList;
