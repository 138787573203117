import React, { useState } from 'react';

import {
  Box,
  Card,
  CardContent,
  Divider,
  Tooltip,
  Grid
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  CustomTypograph,
  CustomTypographLink,
  MainCardData,
  SecondaryCardData,
  CardDataWrapper,
  CustomCardContent,
  CardDataDivider,
  CardTitle,
  CustomHelpIcon,
  CustomHintText,
  InConstruction,
  CustomDownloadIcon,
  CustomCardContentNoPadding,
  CardTitleTypography,
  CustomArrowDropDown,
  CustomArrowDropDownUp
} from '../styles';
import handleOpenNewTabHook from './handleGetTabFilterHook';
import { COLORS } from '../../../styles/settings/colors.config';

import formHelper from '../../../utils/formHelper';

const DividerWithArrowClosed = ({ id }) => {
  return (
    <>
      <Box marginLeft="22px" marginRight="22px">
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="-35px"
        marginTop="-2px"
        id={`${id}-DividerWithArrowClosed`}
      >
        <CustomArrowDropDown iconcolor="#80C343" />
      </Box>
    </>
  );
};

const DividerWithArrowOpened = ({ id }) => {
  return (
    <>
      <Box marginLeft="22px" marginRight="22px">
        <Divider />
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        marginBottom="-29px"
        marginTop="-32px"
        id={`${id}-DividerWithArrowOpened`}
      >
        <CustomArrowDropDownUp iconcolor="#80C343" />
      </Box>
    </>
  );
};

const getTitle = (cardType, isRealTime) => {
  if (cardType === 'openedProtocols' && isRealTime) {
    return (
      <>
        <b>Em Andamento</b>
      </>
    );
  }
  if (cardType === 'openedProtocols' && !isRealTime) {
    return (
      <>
        Protocolos <b>Abertos</b> no Período
      </>
    );
  }

  if (cardType === 'protocols') {
    return <>Protocolos</>;
  }

  if (cardType === 'attendantProtocols') {
    return <>Protocolos por Analistas</>;
  }

  if (cardType === 'kabumCarrefourIncidents') {
    return (
      <>
        <b>Com Incidentes</b>
      </>
    );
  }
  if (cardType === 'cancelProcess') {
    return (
      <>
        <b>Cancelamento e Troca</b>
      </>
    );
  }

  if (cardType === 'n1') {
    return (
      <>
        <b>N1</b>
      </>
    );
  }

  if (cardType === 'n2') {
    return (
      <>
        <b>N2</b>
      </>
    );
  }

  return <></>;
};

const SubtitleSection = ({ subtitle }) => {
  return (
    <CardContent>
      <CardTitleTypography colortext={COLORS.cardMessageBlue}>
        {subtitle}
      </CardTitleTypography>
    </CardContent>
  );
};

const IndividualResumedView = ({
  firstLabel,
  firstData,
  secondaryLabel,
  secondaryData
}) => {
  return (
    <>
      {/** Individual Value */}
      <MainCardData>
        <p className="label">{firstLabel}</p>
        <p className="main-data-stronger">
          {formHelper.addZeroToCompleteTwoDigits(firstData)}
        </p>
      </MainCardData>
      <CardDataDivider dividerMargin="20px" />
      {/** Total Value */}
      <SecondaryCardData>
        <p className="label">{secondaryLabel}</p>
        <p className="main-data">
          {formHelper.addZeroToCompleteTwoDigits(secondaryData)}
        </p>
      </SecondaryCardData>
    </>
  );
};

const TeamResumedView = ({ secondaryLabel, secondaryData }) => {
  return (
    <MainCardData>
      <p className="label">{secondaryLabel}</p>
      <p className="main-data-stronger">
        {formHelper.addZeroToCompleteTwoDigits(secondaryData)}
      </p>
    </MainCardData>
  );
};

const CardCustomHeader = ({
  cardType,
  isRealTime,
  downloadProcotols,
  descriptionHint
}) => {
  const { t } = useTranslation();
  return (
    <CardTitle>
      <CardTitleTypography>
        {getTitle(cardType, isRealTime)}
      </CardTitleTypography>
      <Box style={{ gap: '10px' }} display="flex" justifyContent="flex-end">
        {downloadProcotols && (
          <Tooltip
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window'
                  }
                }
              }
            }}
            placement="top"
            arrow
            title={
              <CustomHintText>
                {t('i18n.dashboard.components.hint_download')}
              </CustomHintText>
            }
          >
            <CustomDownloadIcon
              fontSize="small"
              color="primary"
              onClick={downloadProcotols}
            />
          </Tooltip>
        )}
        {descriptionHint && (
          <Tooltip
            PopperProps={{
              disablePortal: true,
              popperOptions: {
                positionFixed: true,
                modifiers: {
                  preventOverflow: {
                    enabled: true,
                    boundariesElement: 'window'
                  }
                }
              }
            }}
            placement="top"
            arrow
            title={<CustomHintText>{descriptionHint}</CustomHintText>}
          >
            <CustomHelpIcon fontSize="small" color="primary" />
          </Tooltip>
        )}
      </Box>
    </CardTitle>
  );
};

const formatPercent = (percent, goal) => {
  return (
    <span
      style={{
        color: percent * 100 > goal ? 'red' : 'grey',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: '500',
        display: 'flex',
        justifyContent: 'end'
      }}
    >{`${_.isNaN(percent) ? 0 : Math.round(percent * 100)}%`}</span>
  );
};

const ItemData = ({ item, headerData, handleOpenNewTab, cardType }) => {
  const filterMarketPlace = JSON.parse(sessionStorage.getItem('newfilter'));
  return (
    <Grid
      item
      container
      justifycontent="flex-start"
      alignItems="center"
      sm={12}
      lg={cardType === 'attendantProtocols' ? 12 : 6}
      md={cardType === 'attendantProtocols' ? 12 : 6}
      spacing={1}
    >
      <Grid item xs={5} style={{ marginBottom: '-10px' }}>
        <CustomTypographLink
          align="right"
          style={{
            fontSize: headerData ? '24px' : '24px',
            marginInlineStart: '-15px'
          }}
          onClick={() => {
            handleOpenNewTab(item.label);
          }}
        >
          {formHelper.addZeroToCompleteTwoDigits(item.value)}
        </CustomTypographLink>
      </Grid>
      <Grid item xs={7} style={{ marginBottom: '-10px' }}>
        <p
          style={{
            fontSize: '12px',
            fontWeight: '500',
            color: COLORS.dashboardNoDrilldownItems
          }}
        >
          <Box
            component="span"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            gridGap={1}
          >
            {item.label}
            {item.label === 'Aguardando Cliente' && (
              <Tooltip
                placement="top"
                title={
                  <CustomHintText>
                    Magalu: A Acer não consegue encerrar o protocolo após
                    tratativa, portanto, permanece neste status até o devido
                    fechamento pelo próprio Marketplace.
                  </CustomHintText>
                }
              >
                <InfoOutlinedIcon aria-label="magalu" />
              </Tooltip>
            )}
          </Box>
        </p>
      </Grid>
      <Grid item xs={5}>
        {filterMarketPlace?.marketplace[0] !== 'CUSTOMER_GUIDE_B2B' ? (
          <p style={{ fontSize: '12px', fontWeight: '500' }}>
            {formatPercent(item.percent, item.goal)}
          </p>
        ) : null}
      </Grid>
      {filterMarketPlace?.marketplace[0] !== 'CUSTOMER_GUIDE_B2B' ? (
        <Grid item xs={7}>
          <p
            style={{
              fontSize: '12px',
              fontWeight: '500',
              color: COLORS.blueText
            }}
          >
            <>{item.goal ? `Meta <= ${`${item.goal}%`}` : ' '}</>
          </p>
        </Grid>
      ) : null}
    </Grid>
  );
};

const BlueSubtitle = ({ subtitle2 }) => {
  return (
    <CardContent style={{ marginBlockEnd: '-10px' }}>
      <CustomTypograph variant="h6" colortext={COLORS.cardMessageBlue}>
        {subtitle2}
      </CustomTypograph>
    </CardContent>
  );
};

const OrderedSideBySide = ({
  extraData,
  headerData,
  handleOpenNewTab,
  cardType
}) => {
  const filteredData = extraData.filter(item => item.show);
  const arraySize = filteredData.length;
  const mod = arraySize % 2;
  const columnSize = (filteredData.length - mod) / 2;

  return (
    <Grid container spacing={2} style={{ padding: '0px 24px' }}>
      <Grid container item spacing={2} xs={6} direction="row">
        {extraData.slice(0, columnSize + mod).map(item => (
          <ItemData
            {...{ item, headerData, handleOpenNewTab, cardType }}
            key={Math.random()}
          />
        ))}
      </Grid>
      <Grid container item spacing={2} xs={6} direction="row">
        {extraData.slice(columnSize + mod, arraySize).map(item => (
          <ItemData
            {...{ item, headerData, handleOpenNewTab, cardType }}
            key={Math.random()}
          />
        ))}
      </Grid>
    </Grid>
  );
};

const ProtocolCardData = ({
  cardType,
  firstLabel,
  secondaryLabel,
  isTeamView,
  headerData,
  showMoreDetails,
  extraData,
  firstData,
  secondaryData,
  subtitle,
  subtitle2,
  isRealTime,
  descriptionHint,
  showInConstruction,
  downloadProcotols,
  cancelling,
  id
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const catchFilterKeyName = (type = null) => {
    if (cancelling) return 'lastStepName';
    if (type === 'byAnalyst') return type;
    return 'ticketStatus';
  };

  const dashboardFilter = useSelector(state => state.dashboardFilterReducer);
  const { handleOpenNewTab } = handleOpenNewTabHook({
    dashboardFilter,
    t,
    key: catchFilterKeyName(
      cardType === 'attendantProtocols' ? 'byAnalyst' : null
    )
  });

  firstData = firstData < 10 ? `0${firstData}` : firstData;
  secondaryData = secondaryData < 10 ? `0${secondaryData}` : secondaryData;

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Card
        style={{
          width: '100%',
          borderRadius: '20px',
          paddingBottom: showMoreDetails ? '30px' : '0px'
        }}
      >
        <CardCustomHeader
          {...{ cardType, isRealTime, downloadProcotols, descriptionHint }}
        />
        <Divider />
        {subtitle && <SubtitleSection {...{ subtitle }} />}
        {headerData && (
          <CustomCardContent>
            <CardDataWrapper>
              {/** Analyst View */}
              {!isTeamView && (
                <IndividualResumedView
                  {...{ firstLabel, firstData, secondaryLabel, secondaryData }}
                />
              )}

              {/** Expert View */}
              {isTeamView && (
                <TeamResumedView {...{ secondaryLabel, secondaryData }} />
              )}
            </CardDataWrapper>
          </CustomCardContent>
        )}
        {showMoreDetails && (
          <>
            {headerData && !open && (
              <span onClick={() => setOpen(true)} aria-hidden="true">
                <DividerWithArrowClosed id={id} />
              </span>
            )}
            {headerData && open && (
              <span onClick={() => setOpen(false)} aria-hidden="true">
                <DividerWithArrowOpened id={id} />
              </span>
            )}

            {subtitle2 && <BlueSubtitle {...{ subtitle2 }} />}
            {open && (
              <CustomCardContentNoPadding>
                {cardType === 'attendantProtocols' && (
                  <OrderedSideBySide
                    {...{ extraData, headerData, handleOpenNewTab, cardType }}
                  />
                )}
                {cardType !== 'attendantProtocols' && (
                  <Grid
                    container
                    direction="row"
                    justifycontent="flex-start"
                    spacing={1}
                  >
                    {extraData?.map(
                      item =>
                        item.show && (
                          <ItemData
                            {...{ item, headerData, handleOpenNewTab }}
                            key={Math.random()}
                          />
                        )
                    )}
                  </Grid>
                )}
              </CustomCardContentNoPadding>
            )}
          </>
        )}
      </Card>
      {showInConstruction && <InConstruction />}
    </div>
  );
};

export default ProtocolCardData;
