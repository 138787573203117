import { Box, Container, Grid, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { Error } from '@material-ui/icons';
import FetchContentError from '../../../components/FetchContentError';
import Loader from '../../../components/Loader';
import Page from '../../../components/Page';
import GeneralWebSocket from '../../../GeneralWebSocket';
import API from '../../../services/api';
import ModalLinkComplaint from './TicketComponents/ModalLinkComplaint';
import TicketDetails from './TicketComponents/TicketDetails';
import TicketHeader from './TicketComponents/TicketHeader';
import TicketMessages from './TicketComponents/TicketMessages';
import TicketTitle from './TicketComponents/TicketTitle';
import TicketSatisfaction from './TicketSatisfaction/TicketSatisfaction';
import ModalTicketsDetailReclameAqui from './ModalTicketsDetail/reclame_aqui';
import { COLORS } from '../../../styles/settings/colors.config';
import formHelper from '../../../utils/formHelper';
import reasonList from '../../../utils/reasonList';
import { isB2BProtocol } from '../../../utils/b2bHelper';

import { blockMlTicket } from './TicketService/MLblockTicket';
import { loadStatusOptions } from './TicketService/TicketsService';

import ModalCustomerHistoric from './TicketComponents/ModalCustomerHistoric/index';

import userService from '../../../services/user.service';
import ProcessList from './components/ProcessList';
import CancellationAlertList from './components/CancellationAlertList';

import { TicketContainerContext } from './context';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  alert: {
    border: '1px solid #FFC061',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 12px 0',
    backgroundColor: '#FFF7E0',
    color: 'black',
    fontWeight: 'bold',
    padding: '1px',
  },
  alertError: {
    border: '1px solid #f44336',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 0 12px 0',
    backgroundColor: '#ffe7e5',
    color: 'black',
    fontWeight: 'bold',
    padding: '1px',
  },
  alertStrong: {
    color: COLORS.btnDangerColor,
    fontWeight: 'bold',
  },
  alertAttention: {
    color: '#FF9900',
    fontWeight: 'bold',
  },
  alertAttentionError: {
    color: '#f44336',
    fontWeight: 'bold',
  },
}));

let subscription = null;

const TicketContainer = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [chat, setChat] = useState({});
  const chatRef = useRef();

  const [openLinkModal, setOpenLinkModal] = useState(false);
  const [openHistoric, setOpenHistoric] = useState(false);
  const [filterLinkedRaTickets, setFilterLinkedRaTickets] = useState(false);
  const [attendant, setAttendant] = useState('');
  const [attendantsList, setAttendantsList] = useState([]);
  const [dadosCliente, setDadosCliente] = useState([]);
  const [dataFetched, setDataFetched] = useState(false);
  const [dadosPedido, setDadosPedido] = useState([]);
  const [hasFetchError, setFetchError] = useState(false);
  const [ticketStatus, setTicketStatus] = useState();
  const [marketplaceBundle, setMarketplaceBundle] = useState([]);
  const [raTicketsList, setRaTicketsList] = useState([]);
  const [dataRaToShow, setDataRaToShow] = useState('');
  const [openRATicketDetail, setOpenRATicketDetail] = useState(false);
  const [existRaRelatedToProtocolo, setExistRaRelatedToProtocolo] = useState(
    false,
  );
  const [
    attendantAnswerToBeInputed,
    setAttendantAnswerToBeInputed,
  ] = useState();
  const [statusList, setStatusList] = useState([
    'ANSWERED',
    'SUSPENDED',
    'RESOLVED',
  ]);

  const handleReason = reason => {
    const foundReason = reasonList().find(
      el => el.name === reason?.name && el.group === reason?.group,
    );
    if (foundReason) {
      return reason;
    }
    return {
      group: '',
      name: '',
      desc: '',
      comment: '',
    };
  };
  const [reasonNew, setReasonNew] = useState(handleReason(chat?.reasonNew));

  const [socketMessageData, setSocketMessageData] = useState({});
  const [countTickets, setCountTickets] = useState(0);
  const [countTicketsTotal, setCountTicketsTotal] = useState(0);

  const { mlTicketblocked, reason } = blockMlTicket(chat);

  const context = { countTickets, setCountTickets, countTicketsTotal };

  const reasonRequiresComment = detailName => {
    return reasonList().some(el =>
      el?.detail.some(obj => obj?.name === detailName && obj?.requireComment),
    );
  };

  const isReasonFullfield = reasonObj => {
    if (reasonObj?.desc?.trim()?.length === 0) {
      return false;
    }
    if (reasonRequiresComment(reasonObj.desc) && !reasonObj.comment) {
      return false;
    }
    return true;
  };

  const getTicketInformation = async ({
    serviceAttendantLogin,
    orderId,
    marketPlace,
  }) => {
    setAttendantsList([]);
    setAttendant(serviceAttendantLogin);

    if (orderId) {
      try {
        const pedidosResponse = await API.get(
          `/auth/ms-ticket/v1/pedidos/${orderId}${
            marketPlace === 'COLOMBO' ? `?marketplace=${marketPlace}` : ''
          }`,
        );
        // if (!pedidosResponse || !pedidosResponse.data) {
        //   setDataFetched(true);
        // }
        setDadosCliente(pedidosResponse.data.cliente);
        setDadosPedido(pedidosResponse.data);
      } catch (error) {
        setDadosCliente([]);
        setDadosPedido('');
        // setDataFetched(true);
        if (error.response?.status !== 404) {
          enqueueSnackbar(
            `${t('i18n.simplephrases.ERROR')} - API Pedidos/Orders`,
            {
              variant: 'error',
            },
          );
        }
      }
      // setDataFetched(true);
    }

    try {
      const atendenteResponse = await API.get(
        `/auth/ms-users/groups/atendente/users`,
      );

      if (!userService.hasRole('ticket_assign_all')) {
        const filteredAttendant = atendenteResponse?.data?.find(item => {
          return item.name === userService.getUsername();
        });

        if (filteredAttendant) {
          setAttendantsList(filteredAttendant?.sort(formHelper.compareName));
        } else {
          setAttendantsList([]);
        }
      } else {
        setAttendantsList(atendenteResponse.data.sort(formHelper.compareName));
      }
    } catch (error) {
      setAttendantsList(null);
      enqueueSnackbar(`${t('i18n.simplephrases.ERROR')}`, {
        variant: 'error',
      });
    }

    // setDataFetched(true);
  };

  const startWebSocketCommunication = ticketId => {
    const generalWebSocket = GeneralWebSocket.getConnection();
    generalWebSocket.send(
      {
        action: 'subscribe',
        channel: `/tickets/${ticketId}`,
      },
      () => {},
    );

    subscription = generalWebSocket.generalMessageSubject.subscribe(
      incomingValue => {
        if (!incomingValue?.data) return;
        const value = JSON.parse(incomingValue?.data);

        if (value.obj) {
          setSocketMessageData(value.obj);
        }

        if (value?.obj?.id) {
          enqueueSnackbar(t('i18n.ticketcontainer.NEW_WS_UPDATE'), {
            variant: 'success',
          });
          const oldOrderId = chat.orderId;
          setChat(value?.obj);
          setAttendant(value?.obj?.serviceAttendantLogin);
          setTicketStatus(value?.obj?.ticketStatus);
          if (value?.obj?.orderId && value?.obj?.orderId !== oldOrderId) {
            API.get(`/auth/ms-ticket/v1/pedidos/${value?.obj?.orderId}`)
              .then(response => {
                setDadosCliente(response.data.cliente);
                setDadosPedido(response.data);
              })
              .catch(() => {
                enqueueSnackbar(
                  `${t('i18n.simplephrases.ERROR')} - API Pedidos/Orders`,
                  {
                    variant: 'error',
                  },
                );
              });
          }
        }
        if (
          value?.obj?.message?.key ===
          'i18n.ticketcontainer.TICKET_ALREADY_BEING_EDIT'
        ) {
          enqueueSnackbar(t('i18n.ticketcontainer.TICKET_ALREADY_BEING_EDIT'), {
            variant: 'error',
            persist: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
        if (
          value?.obj?.message?.key ===
          'i18n.ticketcontainer.TICKET_SOMEELSE_EDITING'
        ) {
          enqueueSnackbar(t('i18n.ticketcontainer.TICKET_SOMEELSE_EDITING'), {
            variant: 'error',
            persist: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      },
    );
  };

  const getTicket = async () => {
    const {
      match: {
        params: { id },
      },
    } = props;
    const URLTicketId = id;
    startWebSocketCommunication(URLTicketId);
    API.get(`/auth/ms-ticket/v1/tickets/${URLTicketId}`)
      .then(({ data }) => {
        setChat(data);
        setMarketplaceBundle(data);
        setTicketStatus(data.ticketStatus);
        setAttendant(data.serviceAttendantLogin);
        getTicketInformation(data);
        setFetchError(false);
        setDataFetched(true);
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
          variant: 'error',
        });
        setFetchError(true);
      });
  };

  const unsubscribeFromWebsocket = () => {
    const generalWebSocket = GeneralWebSocket.getConnection();
    generalWebSocket.send(
      {
        action: 'unsubscribe',
        channel: `/tickets/${chatRef.current.id}`,
      },
      () => {},
    );
    subscription.unsubscribe();
  };

  const checkRaRelatedToProcotol = () => {
    if (chat?.customerComplaint?.length > 0) {
      setExistRaRelatedToProtocolo(
        chat.customerComplaint.some(el => el.linked === true),
      );
    } else {
      setExistRaRelatedToProtocolo(false);
    }
  };

  const getRATickets = () => {
    const raData = {
      cpf: chat?.customerCPF?.replace(/\D/g, ''),
    };

    function compareID(a, b) {
      let comparison = 0;
      if (Number(a.idReclameAquiAPI) < Number(b.idReclameAquiAPI)) {
        comparison = 1;
      } else if (Number(a.idReclameAquiAPI) > Number(b.idReclameAquiAPI)) {
        comparison = -1;
      }
      return comparison;
    }

    if (chat?.customerCPF || chat?.marketPlace === 'MERCADO_LIVRE') {
      if (isB2BProtocol(chat)) {
        API.post(
          `/auth/ms-ticket/v1/tickets/check-reclame-aqui/${chat?.id}`,
          raData,
        )
          .then(response => {
            let raDataResponse = response.data.sort(compareID);
            raDataResponse = raDataResponse.sort(x => {
              if (x.linked === false || x.linked === null) {
                return 0;
              }
              if (x) {
                return -1;
              }
              return 1;
            });
            setRaTicketsList(raDataResponse);
            setOpenRATicketDetail(false);
          })
          .catch(() => {});
      }
    }

    checkRaRelatedToProcotol();
  };

  const appendStatusList = status => {
    status.map(item => {
      if (!statusList.includes(item)) {
        setStatusList(prevStatusList => [...prevStatusList, item]);
      }
      return item;
    });
  };

  const isMlClaimReturnType = () => {
    return (
      chat?.marketplaceBundle?.mercadoLivreAfterSaleClaimEntity?.type ===
      'returns'
    );
  };

  const isBanned = () => {
    return chat?.marketplaceBundle?.status === 'BANNED';
  };

  const isPreSale = () => {
    return chat?.ticketType === 'PRE_SALE';
  };

  const updateTicketStatus = async (status, newValue) => {
    try {
      await API.post(`/auth/ms-ticket/v1/tickets/${chat.id}/status`, {
        ticketStatus: newValue,
      });
      setTicketStatus(newValue);
      document.activeElement.blur();
    } catch (error) {
      enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
    }
  };

  const handleShowRADetails = ra => {
    setOpenRATicketDetail(true);
    setDataRaToShow(ra);
  };

  const closeShowRaDetails = () => {
    setOpenRATicketDetail(false);
  };

  const handleLinkRATicket = raTicketId => {
    const dataRATicket = { idReclameAquiAPI: raTicketId };
    API.post(
      `/auth/ms-ticket/v1/tickets/assign-reclame-aqui/${chat.id}`,
      dataRATicket,
    )
      .then(() => {
        getRATickets();
      })
      .catch(() => {
        enqueueSnackbar(t('i18n.simplephrases.ERROR'), { variant: 'error' });
      })
      .finally(() => {
        setOpenRATicketDetail(false);
        setOpenLinkModal(false);
      });
  };

  useEffect(() => {
    loadStatusOptions(ticketStatus, chat, mlTicketblocked, setStatusList);
  }, [ticketStatus]);

  useEffect(() => {
    if (isMlClaimReturnType()) {
      appendStatusList(['CLOSED', 'SUSPENDED']);
    }
    if (isBanned() || isPreSale()) {
      appendStatusList(['CLOSED']);
    }
  }, [statusList]);

  useEffect(() => {
    getTicket();
    return () => {
      closeSnackbar();
      unsubscribeFromWebsocket();
    };
  }, []);

  useEffect(() => {
    if (chat?.id && chat?.id !== chatRef?.current?.id) {
      getRATickets();
    }
    chatRef.current = chat;
    setReasonNew(handleReason(chat?.reasonNew));
  }, [chat]);

  useEffect(() => {
    if (countTicketsTotal < countTickets) {
      setCountTicketsTotal(countTickets);
    }
  }, [countTickets, countTicketsTotal]);

  if (hasFetchError) {
    return <FetchContentError fetch={getTicket} />;
  }

  const fraudListKeys = chat?.historicCancellations
    ? Object.keys(chat?.historicCancellations)
    : ['cancellations', 'exchanges'];

  return dataFetched ? (
    <TicketContainerContext.Provider value={context}>
      <Page
        className={classes.root}
        title={t('i18n.ticketcontainer.PAGE_TITLE')}
      >
        <Container maxWidth={false}>
          <TicketTitle
            marketPlace={chat.marketPlace}
            ticketType={chat.ticketType}
            setOpenLinkModal={setOpenLinkModal}
            ticketData={chat}
            attendant={attendant}
            raTicketsList={raTicketsList}
            raTicketsFromProtocol={chat?.customerComplaint}
            setFilterLinkedRaTickets={setFilterLinkedRaTickets}
          />
          <CancellationAlertList {...{ fraudListKeys, chat, classes }} />
          {!isReasonFullfield(reasonNew) && (
            <Alert
              severity="warning"
              className={classes.alert}
              icon={<Error />}
            >
              <span className={classes.alertAttention}>Atenção:</span>{' '}
              {t('i18n.ticketcontainer.REASON_INSTRUCTION1')}{' '}
              <span className={classes.alertStrong}>
                {t('i18n.ticketcontainer.REASON_INSTRUCTION2')}
              </span>{' '}
              {t('i18n.ticketcontainer.REASON_INSTRUCTION3')}
              .
            </Alert>
          )}
          {chat.marketPlaceJuridicalIdList && (
            <Alert
              severity="warning"
              className={classes.alert}
              icon={<Error className={classes.alertStrong} />}
            >
              <span className={classes.alertStrong}>
                {t('i18n.simplewords.WARNING')}:
              </span>{' '}
              {t('i18n.legalprotocol.THIS_PROTOCOL_HAS')}{' '}
              <span style={{ color: COLORS.greenText }}>
                {chat.marketPlaceJuridicalIdList.length}{' '}
                {t('i18n.legalprotocol.LEGAL_PROCESSES')}
              </span>{' '}
              {t('i18n.legalprotocol.LINKED_PROCESSES')}
              <br />
              <ProcessList items={chat.marketPlaceJuridicalIdList} />
            </Alert>
          )}
          {mlTicketblocked && (
            <Alert
              severity="error"
              className={classes.alertError}
              icon={<Error />}
            >
              <span className={classes.alertAttentionError}>
                {t('i18n.simplewords.WARNING')}:
              </span>{' '}
              {`${reason}`}
            </Alert>
          )}
          <TicketHeader
            {...{
              attendantsList,
              statusList,
              marketplaceBundle,
              updateTicketStatus,
              ticketStatus,
              attendant,
              reasonRequiresComment,
              setOpenHistoric,
            }}
            dataOrder={dadosPedido}
            data={chat}
            dataClient={dadosCliente}
            updateAttendantAnswerToBeInputed={message =>
              setAttendantAnswerToBeInputed(message)
            }
            setReason={setReasonNew}
            reason={reasonNew}
          />
          <Box mt={3}>
            <Grid container spacing={2}>
              <Grid item lg={7} md={7} xs={12}>
                <TicketMessages
                  dataFetched={dataFetched}
                  setChat={setChat}
                  chat={chat}
                  setAttendant={setAttendant}
                  attendantAnswerToBeInputed={attendantAnswerToBeInputed}
                  dadosPedido={dadosPedido}
                  getTicket={getTicket}
                  socketMessageData={socketMessageData}
                  setTicketStatus={setTicketStatus}
                  ticketStatus={ticketStatus}
                  attendant={attendant}
                  reason={reasonNew}
                  isReasonFullfield={isReasonFullfield}
                />
                <TicketSatisfaction data={chat} />
              </Grid>

              <Grid item lg={5} md={5} xs={12}>
                <TicketDetails
                  orderData={dadosPedido}
                  data={chat}
                  clientData={dadosCliente}
                  productInfo={marketplaceBundle?.marketplaceBundle?.item}
                  updateAttendantAnswerToBeInputed={message =>
                    setAttendantAnswerToBeInputed(message)
                  }
                  ticketData={chat}
                  existRaRelatedToProtocolo={existRaRelatedToProtocolo}
                  raRefreshTicketsList={getRATickets}
                  setOpenLinkModal={setOpenLinkModal}
                  setFilterLinkedRaTickets={setFilterLinkedRaTickets}
                  openHistoric={openHistoric}
                  setOpenHistoric={setOpenHistoric}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Page>

      <ModalLinkComplaint
        raTickets={raTicketsList}
        raTicketsFromProtocol={chat?.customerComplaint}
        onlyLinkedRaTickets={filterLinkedRaTickets}
        dados={chat}
        openLinkModal={openLinkModal}
        setOpenLinkModal={setOpenLinkModal}
        handleShowRADetails={handleShowRADetails}
      />

      <ModalTicketsDetailReclameAqui
        openState={openRATicketDetail}
        closeParent={closeShowRaDetails}
        data={dataRaToShow}
        linkRATicket={handleLinkRATicket}
      />

      <ModalCustomerHistoric
        open={openHistoric}
        onClose={() => setOpenHistoric(false)}
        customerCpf={dadosPedido.cliente?.documento || chat?.customerCPF}
      />
    </TicketContainerContext.Provider>
  ) : (
    <Loader />
  );
};

export default TicketContainer;
