import userService from '../../../../services/user.service';

export const loadStatusOptions = (status, chat, mlTicketblocked, set) => {
  if (
    chat.marketPlace === 'MERCADO_LIVRE' &&
    mlTicketblocked &&
    userService.hasRole('protocol_change_status')
  ) {
    set(['RESOLVED']);
  } else if (status === 'NEW') {
    set([]);
  } else if (status === 'ANSWERED') {
    if (chat.marketPlace === 'MAGALU') {
      set(['AWAITINGCLOSED']);
    } else if (['KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set(['SUSPENDED']);
    } else {
      set(['RESOLVED', 'SUSPENDED']);
    }
  } else if (status === 'SUSPENDED') {
    if (['MAGALU', 'KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set([]);
    } else {
      set(['RESOLVED']);
    }
  } else if (status === 'RESOLVED') {
    set([]);
  } else if (status === 'CLOSED') {
    set([]);
  } else if (status === 'AWAITING') {
    if (chat.marketPlace === 'MAGALU') {
      set([]);
    } else if (['KABUM', 'CARREFOUR'].includes(chat.marketPlace)) {
      set(['SUSPENDED']);
    } else {
      set(['RESOLVED']);
    }
  } else if (status === 'AWAITINGPARTNER') {
    set([]);
  }

  if (chat?.marketPlace === 'KABUM' || chat?.marketPlace === 'CARREFOUR') {
    switch (status) {
      case 'NEW':
        set([]);
        break;
      case 'RESOLVED':
        set([]);
        break;
      case 'ANSWERED':
        set(['SUSPENDED', 'RESOLVED']);
        break;
      case 'AWAITING':
        set(['RESOLVED']);
        break;
      case 'AWAITINGPARTNER':
        set(['SUSPENDED', 'RESOLVED']);
        break;
      case 'SUSPENDED':
        set(['RESOLVED']);
        break;
      default:
        set(['ANSWERED', 'SUSPENDED', 'RESOLVED']);
        break;
    }
  }
};
