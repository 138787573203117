import React from 'react';
import { useTranslation } from 'react-i18next';
import formHelper from '../../../../utils/formHelper';
import { COLORS } from '../../../../styles/settings/colors.config';
import { compare } from '../../services/dashboardServices';
import ReasonsCard from './ReasonsCard';

const getAttendantsDetails = (attendantList, level) => {
  return attendantList?.map(attendant => {
    return {
      label: attendant.attendant,
      color: COLORS.blueText,
      value:
        level === 'N1' ? attendant.reason_group_n1 : attendant.reason_group_n2,
      divider: false,
      show: true
    };
  });
};

const ReasonsRenderCard = ({ dataEquipDash, isAdmin }) => {
  const { t } = useTranslation();

  const storageFilter = JSON.parse(sessionStorage.getItem('newfilter'));

  const filtered_equip_list = dataEquipDash?.motivos_por_analistas?.filter(
    item => item.attendant === storageFilter?.attendant
  );

  const attendant_equip_list = dataEquipDash.motivos_por_analistas;

  const total_n1_equip = attendant_equip_list?.reduce(
    (acc, item) => acc + item.reason_group_n1,
    0
  );
  const total_n2_equip = attendant_equip_list?.reduce(
    (acc, item) => acc + item.reason_group_n2,
    0
  );
  const total_n1_indiv = filtered_equip_list?.reduce(
    (acc, item) => acc + item.reason_group_n1,
    0
  );
  const total_n2_indiv = filtered_equip_list?.reduce(
    (acc, item) => acc + item.reason_group_n2,
    0
  );

  return (
    <ReasonsCard
      descriptionHint
      headerData
      showMoreDetails
      headerMultipleData={[
        {
          subtitle: 'N1',
          color: COLORS.grayText,
          firstLabel: `${t('i18n.dashboard.components.individual')} N1`,
          secondaryLabel: `${t('i18n.dashboard.components.equip')} N1`,
          firstData: formHelper.addZeroToCompleteTwoDigits(total_n1_indiv),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(total_n1_equip),
          action: false
        },
        {
          subtitle: 'N2',
          color: COLORS.grayText,
          firstLabel: `${t('i18n.dashboard.components.individual')} N2`,
          secondaryLabel: `${t('i18n.dashboard.components.equip')} N2`,
          firstData: formHelper.addZeroToCompleteTwoDigits(total_n2_indiv),
          secondaryData: formHelper.addZeroToCompleteTwoDigits(total_n2_equip),
          action: false
        }
      ]}
      isTeamView={isAdmin}
      extraDataN1={getAttendantsDetails(
        isAdmin
          ? attendant_equip_list
              ?.filter(item => item.reason_group_n1 !== 0)
              .sort((a, b) => compare(b.reason_group_n1, a.reason_group_n1))
          : filtered_equip_list
              ?.filter(item => item.reason_group_n1 !== 0)
              .sort((a, b) => compare(b.reason_group_n1, a.reason_group_n1)),
        'N1'
      )}
      extraDataN2={getAttendantsDetails(
        isAdmin
          ? attendant_equip_list
              ?.filter(item => item.reason_group_n2 !== 0)
              .sort((a, b) => compare(b.reason_group_n2, a.reason_group_n2))
          : filtered_equip_list
              ?.filter(item => item.reason_group_n2 !== 0)
              .sort((a, b) => compare(b.reason_group_n2, a.reason_group_n2)),
        'N2'
      )}
    />
  );
};

export default ReasonsRenderCard;
