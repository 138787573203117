import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Fab,
  // FormControl,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import Loader from '../../components/Loader';
import Page from '../../components/Page';
import config from '../../config';
import API from '../../services/api';
import { CustomBreadCrumbs } from '../../styles/components/CustomDataTable';

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  textEditWidth: {
    width: '100%'
  },
  formControl: {
    width: '100%'
  },
  tableScrollBody: {
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  tableScrollContainer: {
    height: '200px'
  },
  tableRow: {
    border: 'none'
  },
  breadcrumbs: {
    marginTop: '15px',
    marginLeft: '15px'
  }
}));

const AtalhosButton = ({ updateMensagem }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseButton = () => {
    setAnchorEl(null);
  };

  const [options, setOptions] = useState([]);

  const default_keys = [
    'atendente.email',
    'cliente.nome',
    'cliente.email',
    'ticket.id',
    'ticket.dataAbertura',
    'produto',
    'rastreio',
    'pedido',
    'códigoCAP'
  ];

  const getKeys = () => {
    API.get(`${config.backend.macroURL}/shortcuts?origin=macros`)
      .then(response => {
        const { data } = response;
        const temp = [];
        data.shortcuts.forEach(item => {
          temp.push({ label: item, value: `{{${item}}}` });
        });
        setOptions(temp);
      })
      .catch(() => {
        const temp = [];
        default_keys.forEach(item => {
          temp.push({ label: item, value: `{{${item}}}` });
        });
        setOptions(temp);
      });
  };

  const addItem = i => {
    updateMensagem(i.value);
  };

  useEffect(() => {
    getKeys();
  }, []);

  return (
    <>
      <Tooltip title="Adicionar atalhos">
        <Fab size="small" color="primary" onClick={handleClick}>
          <Add />
        </Fab>
      </Tooltip>
      <Popover
        id="simple-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCloseButton}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Container>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box paddingTop={3}>
              <Typography variant="caption">Atalhos</Typography>
            </Box>
            {options.map(item => (
              <Box key={item.label} paddingTop={1} paddingBottom={1}>
                <Chip
                  label={item.label}
                  onClick={() => {
                    addItem(item);
                  }}
                />
              </Box>
            ))}
          </Box>
        </Container>
      </Popover>
    </>
  );
};

const MacrosEdit = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [, forceUpdate] = React.useReducer(x => x + 1, 0);
  const [attendantsList, setAttendantsList] = React.useState([]);
  const [macroId, setMacroId] = React.useState();
  const [nome, setNome] = React.useState();
  const [grupo, setGrupo] = React.useState('ALL');
  const [grupos, setGrupos] = React.useState([]);
  const [descricao, setDescricao] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [acoes, setAcoes] = React.useState();
  const [status, setStatus] = React.useState('');
  const [atendente, setAtendente] = React.useState('');
  // const [tipo, setTipo] = React.useState();
  const [mensagem, setMensagem] = React.useState();
  const [editing, setEditing] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [title, setTitle] = React.useState(t('i18n.macros.NEW_MACRO'));

  const submitData = () => {
    forceUpdate();
    const actions = [];

    if (status) {
      actions.push({
        UPDATE_STATUS: {
          description: '',
          value: status
        }
      });
    }

    if (atendente) {
      actions.push({
        UPDATE_ATTENDANT: {
          description: '',
          value: atendente
        }
      });
    }

    if (mensagem) {
      actions.push({
        INSERT_MESSAGE: {
          description: '',
          value: mensagem
        }
      });
    }

    const data = {
      name: nome,
      description: descricao,
      // eslint-disable-next-line camelcase
      user_group: grupo,
      actions
    };

    if (!nome) {
      enqueueSnackbar(t('i18n.macros.EMPTY_FIELD_NAME'), {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        }
      });
      return;
    }

    if (!editing) {
      API.post(config.backend.macroURL, data)
        .then(() => {
          history.push('/macros');
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        });
    } else {
      data.id = macroId;
      API.put(config.backend.macroURL, data)
        .then(() => {
          history.push('/macros');
        })
        .catch(() => {
          enqueueSnackbar(t('i18n.simplephrases.ERROR'), {
            variant: 'error',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right'
            }
          });
        });
    }
  };

  const getGroups = () => {
    API.get(`${config.backend.url}/auth/ms-users/groups`).then(response => {
      const { data } = response;
      const temp = [];
      data.forEach(item => {
        temp.push(item.name);
      });
      setGrupos(temp);
      setLoaded(true);
    });
  };

  React.useEffect(() => {
    getGroups();
    const editData = history?.location?.state;
    if (!editData) return;
    setTitle(t('i18n.macros.EDIT_MACRO'));
    setMacroId(editData.id);
    setNome(editData.nome);
    setGrupos(editData.grupos);
    setDescricao(editData.descricao);
    setAcoes(editData.acoes);
    setEditing(editData.editing);
    let oMensagem;
    let oStatus;
    let oAtendente;
    // let oTipo;
    const len = editData?.acoes?.length;
    if (!len || len === 0) return;
    for (let i = 0; i < len; i++) {
      if (editData?.acoes[i]?.INSERT_MESSAGE?.value)
        oMensagem = editData?.acoes[i]?.INSERT_MESSAGE?.value;

      if (editData?.acoes[i]?.UPDATE_STATUS?.value)
        oStatus = editData?.acoes[i]?.UPDATE_STATUS?.value;

      if (editData?.acoes[i]?.UPDATE_ATTENDANT?.value)
        oAtendente = editData?.acoes[i]?.UPDATE_ATTENDANT?.value;

      if (editData?.acoes[i]?.DEFINE_SUBJECT?.value)
        oMensagem = editData?.acoes[i]?.DEFINE_SUBJECT?.value;
    }

    setMensagem(oMensagem);
    setStatus(oStatus);
    setAtendente(oAtendente);
  }, []);

  function compare(a, b) {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    let comparison = 0;
    if (nameA > nameB) {
      comparison = 1;
    } else if (nameA < nameB) {
      comparison = -1;
    }
    return comparison;
  }

  React.useEffect(() => {
    if (attendantsList.length > 0) return;
    API.get(`/auth/ms-users/users`).then(response => {
      const users = response.data.sort(compare);
      users.unshift({ name: t('i18n.newAutomation.NO_OPTION') });
      setAttendantsList(users);
    });
  }, [attendantsList]);

  const inserirAtalho = atalho => {
    const msg = mensagem || '';
    setMensagem(msg + atalho);
  };

  const getActions = () => {
    // const statuses = [
    //   { label: t('i18n.newAutomation.NO_OPTION'), value: '' },
    //   { label: t('i18n.newAutomation.NEW'), value: 'NEW' },
    //   { label: t('i18n.newAutomation.ANSWERED'), value: 'ANSWERED' },
    //   { label: t('i18n.newAutomation.AWAITING'), value: 'AWAITING' },
    //   { label: t('i18n.newAutomation.SUSPENDED'), value: 'SUSPENDED' },
    //   { label: t('i18n.newAutomation.RESOLVED'), value: 'RESOLVED' },
    //   { label: t('i18n.newAutomation.CLOSED'), value: 'CLOSED' }
    // ];

    const customActions = [
      // {
      //   id: 1,
      //   action: (
      //     <Typography variant="caption">
      //       {`${t('i18n.macros.CHANGE')} ${t('i18n.macros.STATUS')}`}
      //     </Typography>
      //   ),
      //   value: (
      //     <Box paddingBottom={2}>
      //       <FormControl fullWidth variant="outlined">
      //         <InputLabel>{t('i18n.macros.STATUS')}</InputLabel>
      //         <Select
      //           size="small"
      //           label={t('i18n.macros.STATUS')}
      //           variant="outlined"
      //           fullWidth
      //           value={status}
      //           onChange={ev => {
      //             setStatus(ev.target.value);
      //           }}
      //           onBlur={ev => {
      //             setStatus(ev.target.value);
      //           }}
      //         >
      //           {statuses.map(item => (
      //             <MenuItem key={item.value} value={item.value}>
      //               {item.label}
      //             </MenuItem>
      //           ))}
      //         </Select>
      //       </FormControl>
      //     </Box>
      //   )
      // },
      // {
      //   id: 2,
      //   action: (
      //     <Typography variant="caption">
      //       {`${t('i18n.macros.CHANGE')} ${t('i18n.macros.ATTENDANT')}`}
      //     </Typography>
      //   ),
      //   value: (
      //     <Box paddingBottom={2}>
      //       <FormControl fullWidth variant="outlined">
      //         <InputLabel>{t('i18n.macros.ATTENDANT')}</InputLabel>
      //         <Select
      //           size="small"
      //           label={t('i18n.macros.ATTENDANT')}
      //           variant="outlined"
      //           fullWidth
      //           value={atendente}
      //           onChange={ev => {
      //             if (ev.target.value === t('i18n.newAutomation.NO_OPTION')) {
      //               ev.target.value = '';
      //             }
      //             setAtendente(ev.target.value);
      //           }}
      //           onBlur={ev => {
      //             if (ev.target.value === t('i18n.newAutomation.NO_OPTION')) {
      //               ev.target.value = '';
      //             }
      //             setAtendente(ev.target.value);
      //           }}
      //         >
      //           {attendantsList.map(item => (
      //             <MenuItem key={item.name} value={item.name}>
      //               {item.name}
      //             </MenuItem>
      //           ))}
      //         </Select>
      //       </FormControl>
      //     </Box>
      //   )
      // },
      // {
      //   action: (
      //     <Typography variant="caption">{t('i18n.macros.TYPE')}</Typography>
      //   ),
      //   value: (
      //     <Box paddingBottom={2}>
      //       <Select
      //         size="small"
      //         labelId="select-tipo"
      //         variant="outlined"
      //         fullWidth
      //         value={tipo}
      //         onChange={ev => {
      //           setTipo(ev.target.value);
      //         }}
      //         onBlur={ev => {
      //           setTipo(ev.target.value);
      //         }}
      //       >
      //         {ticketTypes.map(item => (
      //           <MenuItem value={item.value}>{item.label}</MenuItem>
      //         ))}
      //       </Select>
      //     </Box>
      //   )
      // },
      {
        id: 3,
        action: (
          <Typography variant="caption">
            {`${t('i18n.macros.CHANGE')} ${t('i18n.macros.MESSAGE')}`}
          </Typography>
        ),
        value: (
          <Box display="flex" flexDirection="row">
            <Box paddingRight={2} flexGrow={1}>
              <TextField
                variant="outlined"
                label={t('i18n.macros.MESSAGE')}
                fullWidth
                multiline
                minRows={4}
                value={mensagem}
                size="small"
                onChange={ev => {
                  setMensagem(ev.target.value);
                }}
                onBlur={ev => {
                  setMensagem(ev.target.value);
                }}
              />
            </Box>
            <Box>
              <AtalhosButton updateMensagem={inserirAtalho} />
            </Box>
          </Box>
        )
      }
    ];

    return customActions.map(row => (
      <TableRow key={row.id}>
        {/* <TableCell>{row.action}</TableCell> */}
        <TableCell className={classes.tableRow}>{row.value}</TableCell>
      </TableRow>
    ));
  };

  if (!grupos) setGrupos([]);

  return (
    <Page className={classes.root} title="Macros">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxwidth="false">
          <CustomBreadCrumbs
            className={classes.breadcrumbs}
            gutterBottom
            aria-label="breadcrumb"
          >
            <Typography variant="h6">
              <Link
                onClick={() => {
                  history.push('/macros');
                }}
                color="inherit"
                to="/macros"
              >
                Macros
              </Link>
            </Typography>
            <Typography variant="h6" color="textPrimary">
              {title}
            </Typography>
          </CustomBreadCrumbs>
          {loaded ? (
            <Box mb={3}>
              <Card>
                <CardContent>
                  <Box display="flex" flexDirection="column">
                    <Box paddingBottom={2}>
                      <TextField
                        variant="outlined"
                        label={t('i18n.macros.NAME')}
                        fullWidth
                        value={nome}
                        size="small"
                        onChange={ev => {
                          setNome(ev.target.value);
                        }}
                      />
                    </Box>
                    <Box paddingBottom={2}>
                      <InputLabel size="small" id="select-grupos">
                        {t('i18n.macros.GROUPS')}
                      </InputLabel>
                      <Select
                        size="small"
                        labelId="select-grupos"
                        variant="outlined"
                        fullWidth
                        value={grupo}
                        onChange={ev => {
                          setGrupo(ev.target.value);
                        }}
                        onBlur={ev => {
                          setGrupo(ev.target.value);
                        }}
                      >
                        <MenuItem value="ALL">Todos</MenuItem>
                        {grupos
                          ? grupos.map(item => (
                              <MenuItem key={item} value={item.name}>
                                {item}
                              </MenuItem>
                            ))
                          : null}
                      </Select>
                    </Box>
                    <Box paddingBottom={2}>
                      <TextField
                        label={t('i18n.macros.DESCRIPTION')}
                        variant="outlined"
                        multiline
                        minRows={4}
                        fullWidth
                        value={descricao}
                        size="small"
                        onChange={ev => {
                          setDescricao(ev.target.value);
                        }}
                      />
                    </Box>
                    <Box paddingBottom={1}>
                      <Box display="flex" flexDirection="column">
                        <Box paddingTop={3}>
                          <TableContainer component={Paper}>
                            <Table
                              className={classes.table}
                              size="small"
                              // aria-label="a dense table"
                            >
                              <TableHead>
                                <TableRow>
                                  <TableCell>
                                    <Typography
                                      variant="subtitle1"
                                      color="textSecondary"
                                    >
                                      {t('i18n.macros.INSTRUCTIONS')}
                                    </Typography>
                                  </TableCell>
                                  {/* <TableCell>
                                    {t('i18n.macros.VALUE')}
                                  </TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>{getActions()}</TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-end"
                    >
                      <Box paddingRight={2}>
                        <Button
                          variant="contained"
                          color="default"
                          onClick={() => {
                            history.push('/macros');
                          }}
                        >
                          {t('i18n.macros.CANCEL')}
                        </Button>
                      </Box>
                      <Box paddingRight={3}>
                        <Button
                          disabled={!status && !atendente && !mensagem}
                          variant="contained"
                          color="primary"
                          onClick={submitData}
                        >
                          {t('i18n.macros.SAVE')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Box>
          ) : (
            <Loader />
          )}
        </Container>
      </Box>
    </Page>
  );
};

export default MacrosEdit;
