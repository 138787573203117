import {
  Box,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ChangeDataType = ({ properties, setProperties }) => {
  const { t } = useTranslation();
  const [actionProperties, setActionProperties] = useState(properties);
  const setActionPropsState = (key, value) => {
    const newState = { ...actionProperties };
    newState[key] = value;
    setActionProperties(newState);
  };

  useEffect(() => {
    setProperties(actionProperties);
  }, [actionProperties]);

  const ticketTypeOptions = [
    { label: t('i18n.newAutomation.NO_OPTION'), value: null },
    { label: t('i18n.newAutomation.PRE_SALE'), value: 'PRE_SALE' },
    { label: t('i18n.newAutomation.AFTER_SALE'), value: 'AFTER_SALE' }
  ];

  const ticketStatusOptions = [
    { label: t('i18n.newAutomation.NO_OPTION'), value: null },
    { label: t('i18n.newAutomation.NEW'), value: 'NEW' },
    { label: t('i18n.newAutomation.ANSWERED'), value: 'ANSWERED' },
    { label: t('i18n.newAutomation.AWAITING'), value: 'AWAITING' },
    { label: t('i18n.newAutomation.SUSPENDED'), value: 'SUSPENDED' },
    { label: t('i18n.newAutomation.RESOLVED'), value: 'RESOLVED' },
    { label: t('i18n.newAutomation.CLOSED'), value: 'CLOSED' }
  ];

  const vipClientOptions = [
    { label: t('i18n.newAutomation.NO_OPTION'), value: null },
    { label: t('i18n.newAutomation.YES'), value: true },
    { label: t('i18n.newAutomation.NO'), value: false }
  ];

  return (
    <CardContent>
      <Typography variant="overline">
        {t('i18n.newAutomation.DATA_TO_BE_CHANGED')}
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Grid container spacing={2}>
          <Grid lg={4} item>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('i18n.newAutomation.ticketType')}</InputLabel>
              <Select
                value={actionProperties.ticketType || ''}
                label={t('i18n.newAutomation.ticketType')}
                onChange={ev =>
                  setActionPropsState('ticketType', ev.target.value)
                }
              >
                {ticketTypeOptions.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid lg={4} item>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('i18n.newAutomation.ticketStatus')}</InputLabel>
              <Select
                value={actionProperties.ticketStatus || ''}
                label={t('i18n.newAutomation.ticketStatus')}
                onChange={ev =>
                  setActionPropsState('ticketStatus', ev.target.value)
                }
              >
                {ticketStatusOptions.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid lg={4} item>
            <FormControl fullWidth variant="outlined">
              <InputLabel>{t('i18n.newAutomation.vipClient')}</InputLabel>
              <Select
                value={actionProperties.vipClient}
                label={t('i18n.newAutomation.vipClient')}
                onChange={ev =>
                  setActionPropsState('vipClient', ev.target.value)
                }
              >
                {vipClientOptions.map(item => {
                  return (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </CardContent>
  );
};

export default ChangeDataType;
