import React, { useState, useEffect } from 'react';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ForumIcon from '@material-ui/icons/Forum';
import PersonIcon from '@material-ui/icons/Person';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import userService from '../../services/user.service';

const MenuHooks = () => {
  const { t } = useTranslation();
  const [favoriteSearched, setFavoriteSearched] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const loadFavorites = useSelector((state) => state.loadFavoritesReducer);

  const handleFindLink = (newLinks = undefined) => {
    const linksList = [];
    const links = newLinks || loadFavorites;
    links.forEach((item) => {
      item.fields.forEach((subitem) => {
        const linkSearched = linksList.find((i) => i.value === subitem.value);
        if (!linkSearched) {
          subitem.favoriteDetails = {
            name: item.name,
            id: item.id,
            userName: item.userName
          };
          linksList.push(subitem);
        }
      });
    });
    setFavoriteSearched(linksList);
  };

  const handleSearchedLink = (item, list) => {
    const linkSearched = list.find((i) => i.value === item);
    if (linkSearched) {
      return linkSearched;
    }
    return false;
  };

  const handleShowFavoriteLinks = (fav) => {
    const items = [
      {
        id: 1,
        path: '/dashboardPanel',
        text: t('i18n.dashboardlayout.DASHBOARD'),
        icon: <DashboardIcon />,
        role: 'dashboard',
        show: userService.hasRole('dashboard'),
        edit: handleSearchedLink('/dashboardPanel', fav)
      },
      {
        id: 2,
        path: '/ticketsAfterSale',
        text: t('i18n.dashboardlayout.AFTER'),
        text2: `-${t('i18n.dashboardlayout.SALE')}`,
        role: 'ticket_list',
        show: userService.hasRole('ticket_list'),
        edit: handleSearchedLink('/ticketsAfterSale', fav)
      },
      // {
      //   id: 3,
      //   text: t('i18n.dashboardlayout.CANCELLING'),
      //   icon: <AccountTreeIcon />,
      //   role: 'ticket_list',
      //   show: userService.hasRole('ticket_list'),
      //   items: [
      //     {
      //       id: 1,
      //       path: '/department/orders/list',
      //       text: t('i18n.dashboardlayout.TI_ORDERS'),
      //       icon: <ForumIcon />,
      //       role: 'ticket_list',
      //       show: userService.hasRole('ticket_list'),
      //       edit: handleSearchedLink('/department/orders/list', fav)
      //     },
      //     {
      //       id: 2,
      //       path: '/department/refund/list',
      //       text: t('i18n.dashboardlayout.SERVICE_REFUND'),
      //       icon: <ForumIcon />,
      //       role: 'ticket_list',
      //       show: userService.hasRole('ticket_list'),
      //       edit: handleSearchedLink('/department/refund/list', fav)
      //     },
      //     {
      //       id: 3,
      //       path: '/department/logistics/list',
      //       text: t('i18n.dashboardlayout.LOGISTICS'),
      //       icon: <ForumIcon />,
      //       role: 'ticket_list',
      //       show: userService.hasRole('ticket_list'),
      //       edit: handleSearchedLink('/department/logistics/list', fav)
      //     }
      //   ]
      // },
      {
        id: 4,
        text: t('i18n.dashboardlayout.PRE'),
        text2: `-${t('i18n.dashboardlayout.SALE')}`,
        // role: 'ticket_pre_list',
        role: ['ticket_pre_list', 'chat', 'chat_b2b', 'monitor_chat'],
        // role: 'chat',
        show:
          userService.hasRole('ticket_pre_list') ||
          userService.hasRole('chat') ||
          userService.hasRole('chat_b2b') ||
          userService.hasRole('monitor_chat'),
        items: [
          // {
          //   id: 7,
          //   path: '/ticketsEvaluation',
          //   text: t('i18n.dashboardlayout.EVALUATIONS'),
          //   role: 'evaluations',
          //   show: userService.hasRole('evaluations'),
          //   edit: handleSearchedLink('/ticketsEvaluation', fav)
          // },
          {
            id: 1,
            path: '/ticketsPreSale',
            text: t('i18n.dashboardlayout.PRE_SALE_OFFOLINE'),
            icon: <ChatIcon />,
            role: 'ticket_pre_list',
            show: userService.hasRole('ticket_pre_list'),
            offline: true,
            openNewTab: true,
            edit: handleSearchedLink('/ticketsPreSale', fav)
          },
          {
            id: 6,
            text: 'Online B2C',
            show: userService.hasRole('chat')
          },
          {
            id: 2,
            path: '/chat',
            text: `- ${t('i18n.dashboardlayout.CHATS')} B2C`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            show: userService.hasRole('chat'),
            subItem: true,
            edit: handleSearchedLink('/chats', fav)
          },

          {
            id: 3,
            path: '/chat/historico',
            text: `- ${t('i18n.dashboardlayout.CHAT_HISTORY')} B2C`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat'),
            subItem: true,
            edit: handleSearchedLink('/chat/historico', fav)
          },

          {
            id: 4,
            path: '/chat/clientes-bloqueados',
            text: `- ${t('i18n.chatview.BLOCKED_IPS')} B2C`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat'),
            subItem: true,
            edit: handleSearchedLink('/chat/clientes-bloqueados', fav)
          },

          {
            id: 5,
            path: '/monitor/chat',
            text: `- ${t('i18n.monitor.chat.CHAT_MONITOR_MENU')} B2C`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('monitor_chat'),
            subItem: true,
            edit: handleSearchedLink('/monitor/chat', fav)
          },
          {
            id: 10,
            path: '/monitor/chat/tv',
            text: `- ${t('i18n.monitor.chat.CHAT_MONITOR_MENU')} TV B2C`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('monitor_chat'),
            subItem: true,
            edit: handleSearchedLink('/monitor/chat/tv', fav)
          },
          {
            id: 9,
            text: 'Online B2B',
            show: userService.hasRole('chat_b2b')
          },
          {
            id: 7,
            path: '/chat/b2b',
            text: `- ${t('i18n.dashboardlayout.CHATS')} B2B`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            show: userService.hasRole('chat_b2b'),
            subItem: true,
            edit: handleSearchedLink('/chat/b2b', fav)
          },
          {
            id: 8,
            path: '/chat/historico/b2b',
            text: `- ${t('i18n.dashboardlayout.CHAT_HISTORY')} B2B`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat_b2b'),
            subItem: true,
            edit: handleSearchedLink('/chat/historico/b2b', fav)
          },
          {
            id: 13,
            path: '/chat/clientes-bloqueados/b2b',
            text: `- ${t('i18n.chatview.BLOCKED_IPS')} B2B`,
            icon: <ForumIcon />,
            role: 'chat',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat_b2b'),
            subItem: true,
            edit: handleSearchedLink('/chat/clientes-bloqueados/b2b', fav)
          },
          {
            id: 12,
            text: t('i18n.chatview.CHAT_CONFIG'),
            show:
              userService.hasRole('attending_time') ||
              userService.hasRole('chat_trigger')
          },
          {
            id: 11,
            path: '/chats/settings',
            text: `- ${t('i18n.monitor.chat.CHAT_SETTINGS')} B2C`,
            icon: <ForumIcon />,
            role: 'attending_time',
            online: true,
            openNewTab: true,
            show: userService.hasRole('attending_time'),
            subItem: true,
            edit: handleSearchedLink('/chats/settings', fav)
          },
          {
            id: 14,
            path: '/chats/triggers',
            text: `- ${t('i18n.monitor.chat.CHAT_TRIGGERS')} B2C`,
            icon: <ForumIcon />,
            role: 'chat_trigger',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat_trigger'),
            subItem: true,
            edit: handleSearchedLink('/chats/triggers', fav)
          },
          {
            id: 15,
            path: '/chats/settings/b2b',
            text: `- ${t('i18n.monitor.chat.CHAT_SETTINGS')} B2B`,
            icon: <ForumIcon />,
            role: 'attending_time',
            online: true,
            openNewTab: true,
            show: userService.hasRole('attending_time'),
            subItem: true,
            edit: handleSearchedLink('/chats/settings/b2b', fav)
          },
          {
            id: 16,
            path: '/chats/triggers/b2b',
            text: `- ${t('i18n.monitor.chat.CHAT_TRIGGERS')} B2B`,
            icon: <ForumIcon />,
            role: 'chat_trigger',
            online: true,
            openNewTab: true,
            show: userService.hasRole('chat_trigger'),
            subItem: true,
            edit: handleSearchedLink('/chats/triggers/b2b', fav)
          }
        ]
      },
      {
        id: 5,
        text: t('i18n.dashboardlayout.TOOLS'),
        role: ['sales_ad_analysis', 'tags'],
        show: userService.hasRole('tags'),
        items: [
          {
            id: 1,
            action: 'setOpenSearchLegalProcess',
            text: t('i18n.legalprotocol.LEGAL'),
            show:
              userService.hasGroup('admin') || userService.hasGroup('atendente')
          },
          {
            id: 2,
            path: '/monitor',
            text: t('i18n.dashboardlayout.MONITOR_MARKETPLACES'),
            show:
              userService.hasGroup('admin') ||
              userService.hasGroup('atendente'),
            edit: handleSearchedLink('/monitor', fav)
          },
          {
            id: 3,
            action: 'setOpenInvoice',
            text: t('i18n.invoicemodal.INVOICE'),
            show:
              userService.hasGroup('admin') || userService.hasGroup('atendente')
          },
          {
            id: 4,
            action: 'setOpenOrders',
            text: t('i18n.ordersmodal.ORDER_CODE'),
            show:
              userService.hasGroup('admin') || userService.hasGroup('atendente')
          },
          {
            id: 5,
            action: 'setOpenTracking',
            text: t('i18n.trackingmodal.TRACKING'),
            show:
              userService.hasGroup('admin') || userService.hasGroup('atendente')
          },
          {
            id: 6,
            action: 'setOpenMarketplaceSupportModal',
            text: t('i18n.dashboardlayout.MARKETPLACE_SUPPORT'),
            role: 'support_list',
            show: userService.hasRole('support_list')
          }
          // {
          //   id: 7,
          //   path: '/tags',
          //   text: 'Tags',
          //   role: 'tags',
          //   show: userService.hasRole('tags'),
          //   edit: handleSearchedLink('/tags', fav)
          // }
        ]
      },
      {
        id: 6,
        text: t('i18n.dashboardlayout.MANAGEMENT_AREA'),
        role: 'user',
        show:
          userService.hasRole('automations') ||
          userService.hasRole('campaign') ||
          userService.hasRole('system_params_list') ||
          userService.hasRole('company_list') ||
          userService.hasRole('knowledge_base') ||
          userService.hasRole('macros') ||
          userService.hasRole('user'),
        items: [
          {
            id: 1,
            path: '/automations',
            text: t('i18n.dashboardlayout.AUTOMATIONS'),
            icon: <AccountTreeIcon />,
            role: 'automations',
            show: userService.hasRole('automations'),
            edit: handleSearchedLink('/automations', fav)
          },
          {
            id: 2,
            path: '/campaign-calendar',
            text: t('i18n.simplewords.CAMPAIGNS'),
            icon: <AccountTreeIcon />,
            role: 'campaign',
            show: userService.hasRole('campaign'),
            edit: handleSearchedLink('/campaign-calendar', fav)
          },
          {
            id: 3,
            path: '/setup',
            text: t('i18n.dashboardlayout.SETUP'),
            icon: <AccountTreeIcon />,
            role: 'system_params_list',
            show: userService.hasRole('system_params_list'),
            edit: handleSearchedLink('/setup', fav)
          },
          {
            id: 4,
            path: '/companies',
            text: t('i18n.dashboardlayout.COMPANIES'),
            icon: <AccountTreeIcon />,
            role: 'company_list',
            show: userService.hasRole('company_list'),
            edit: handleSearchedLink('/companies', fav)
          },
          {
            id: 5,
            path: '/knowledge-base',
            text: 'FAQ',
            icon: <AccountTreeIcon />,
            role: 'knowledge_base',
            show: userService.hasRole('knowledge_base'),
            edit: handleSearchedLink('/knowledge-base', fav)
          },
          {
            id: 6,
            path: '/macros',
            text: 'Macros',
            icon: <AccountTreeIcon />,
            role: 'macros',
            show: userService.hasRole('macros'),
            edit: handleSearchedLink('/macros', fav)
          },
          {
            id: 7,
            path: '/quick-answers',
            text: t('i18n.dashboardlayout.QUICK_ANSWERS'),
            icon: <AccountTreeIcon />,
            role: 'macros',
            show: userService.hasRole('macros'),
            edit: handleSearchedLink('/quick-answers', fav)
          },
          {
            id: 8,
            path: '/users-management',
            text: `${t('i18n.dashboardlayout.USERS')}`,
            icon: <PersonIcon />,
            role: 'user',
            show: userService.hasRole('user'),
            edit: handleSearchedLink('/users', fav)
          }
          // {
          //   id: 9,
          //   path: '/profiles',
          //   text: t('i18n.dashboardlayout.PROFILES'),
          //   icon: <PersonIcon />,
          //   role: 'user',
          //   show: userService.hasRole('profile'),
          //   edit: handleSearchedLink('/profiles', fav)
          // }
        ]
      }
    ];

    return items;
  };

  useEffect(() => {
    handleFindLink();
  }, [loadFavorites]);

  useEffect(() => {
    setMenuItems(handleShowFavoriteLinks(favoriteSearched));
  }, [favoriteSearched]);

  return { menuItems };
};

export { MenuHooks };
