import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useHistory, Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import loginBg from '../../assets/images/login-bg.jpg';
import conectaLogo from '../../assets/images/logo-login-082021.png';
import authService from '../../auth/auth.service';
import config from '../../config';
import LanguageButton from '../../layouts/DashboardLayout/Tools/LanguageButton';
import API from '../../services/api';
import userService from '../../services/user.service';

const LoginContainer = styled.section`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  .login-content {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    height: 100vh;
    padding: 0 8px;
    width: 100%;
    @media screen and (min-width: 768px) {
      padding: 0 24px;
      width: 50%;
    }

    @media screen and (min-width: 1024px) {
      justify-content: flex-start;
      padding: 48px;
      width: 35%;
    }
    @media screen and (min-width: 1920px) {
      width: 30%;
    }

    &-header {
      align-items: center;
      bottom: 20vh;
      display: flex;
      justify-content: space-between;
      position: relative;
      @media screen and (min-width: 768px) {
        position: initial;
      }
      img {
        width: 250px;
      }
    }

    &-form {
      width: 100%;
      @media screen and (min-width: 1024px) {
        position: relative;
        top: 25vh;
      }

      button {
        height: 56px;
        width: 100%;
      }
    }
  }

  .login-background {
    display: none;
    @media screen and (min-width: 768px) {
      background: #4caf50;
      background-image: url(${loginBg});
      background-position: center;
      background-size: cover;
      display: block;
      height: 100vh;
      width: 50%;
    }
    @media screen and (min-width: 1024px) {
      width: 65%;
    }
    @media screen and (min-width: 1920px) {
      width: 70%;
    }

    &::after {
      display: none;
      @media screen and (min-width: 768px) {
        background: linear-gradient(to right, #ffffff70, #80c343ad);
        content: ' ';
        display: block;
        height: 100%;
        position: absolute;
        width: 50%;
      }
      @media screen and (min-width: 1024px) {
        width: 65%;
      }
      @media screen and (min-width: 1920px) {
        width: 70%;
      }
    }
  }
`;

const LoginView = () => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const [formValues, setFormValues] = useState({
    username: '',
    password: ''
  });

  const handleSuccess = data => {
    if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
      sessionStorage.setItem('session', data.session);
      history.push('/change-password', {
        state: {
          username: formValues.username,
          password: formValues.password
        }
      });
      return;
    }
    authService.setAccessTokens(data);
    if (location.state?.link) history.push(location.state?.link);
    else history.push(userService.getFirstAvailableRoute());
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { username, password } = formValues;
    try {
      const { data } = await API.post(`${config.cognito.authUrl}/login`, {
        username,
        password
      });
      handleSuccess(data);
    } catch (error) {
      if (error.response.data.key === 'i18n.login.USER_DISABLED') {
        enqueueSnackbar(t('i18n.login.USER_DISABLED'), {
          variant: 'error'
        });
      } else {
        enqueueSnackbar(t('i18n.loginview.LOGIN_ERROR'), {
          variant: 'error'
        });
      }
    }
  };

  return (
    <LoginContainer>
      <div className="login-content">
        <div className="login-content-header">
          <img src={conectaLogo} alt="" />
          <LanguageButton />
        </div>
        <div className="login-content-form">
          <form onSubmit={handleSubmit}>
            <Box paddingTop={3}>
              <TextField
                label={t('i18n.loginview.LOGIN')}
                variant="outlined"
                fullWidth
                value={formValues?.username}
                onChange={e =>
                  setFormValues({ ...formValues, username: e.target.value })
                }
                id="name-user"
              />
            </Box>
            <Box paddingTop={3}>
              <TextField
                label={t('i18n.loginview.PASSWORD')}
                type="password"
                variant="outlined"
                fullWidth
                value={formValues?.password}
                onChange={e =>
                  setFormValues({ ...formValues, password: e.target.value })
                }
                id="password-user"
              />
            </Box>
            <Box paddingTop={3}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                id="button-login"
                disabled={!formValues?.username || !formValues?.password}
              >
                {t('i18n.loginview.ENTER')}
              </Button>
            </Box>
          </form>
          <Link to="/reset-password" style={{ textDecoration: 'none' }}>
            <Typography
              style={{ marginTop: '1.5em' }}
              color="primary"
              align="center"
              id="reset-password"
            >
              {t('i18n.loginview.RETRIEVE_PASSWORD_LINK')}
            </Typography>
          </Link>
        </div>
      </div>
      <div className="login-background" />
    </LoginContainer>
  );
};

export default LoginView;
